<template>
<div class="container-fluid container-color">
  <div class="row justify-content-md-center">
      <div class="col-md-7 col-xs-12">
        <QBPForm/>
      </div>
    </div>
    <div class="row row-margin">
      <div class="col-12">
        <QuickPayRightBar/>
      </div>
    </div>
</div>

</template>

<script>

import QBPForm from '../components/qbp/QBPForm.vue'
import QuickPayRightBar from '../components/qbp/quickPayRightBar.vue'

export default {
  name: 'Identify',
  components: {
    QBPForm,
    QuickPayRightBar
  }
}
</script>
<style scoped>
  .row-margin {
    padding-left: 10%;
    background: #ebeff6 0% 0% no-repeat padding-box;
    margin-top: 20px;
    display: flex;
  }
  @media (min-width: 768px) {
  .row-margin {
    display: flex;
  }
}
.container-color {
  background-color: #FAFAFA;
  margin-top: -20px;
  padding-top: 20px;
}
</style>