<template>
<div class="container">
    <div class="row">
    <h1 class="text-decor-left">Your AutoPay Details</h1>
    <div v-if="(HCDE110) || (timeOutValue === 'timeOut')" id="paymentError">
        <div class="pleaseEnterEmailMessage" data-galink-type="chat">
            <h2>Your session has timed out.</h2>
            <p>For the security of your account, your session was ended due to inactivity.</p>
            <p>Would you like to <a :href="`/autopay/manage?token=${token}`" id="startoverandtryagain"> start over and try again</a>? </p>					
        </div>
    </div>
    <div v-if="(HCDE011)" id="paymentError"> 
        <div class="errorBox" data-galink-type="chat">
            <h2>Sorry - you've reached the maximum allowable attempts.</h2>
            <p>We do this for security purposes.</p>			
            <p>Not to worry though, you'll just need to <a :href="`/autopay/manage?token=${token}`" id="startover"> start over</a>.</p>
        </div>
    </div>
    <div v-if="HCDE10017" id="paymentError">
        <div class="pleaseEnterEmailMessage">
            <h2>There's a problem with your account information.</h2>
            
            <p>The email address for your account appears to be invalid. Before you can make a payment online, please 
            <a :href="EmailPreferencesURL" target="_blank" id="update_the_email_address">update the email address</a> 
            you have on record with us.</p>
            <p style="margin-bottom:0;">If you believe your email address is valid and you continue seeing this error message, please 
            <a href="javascript:startChat({skillName:'Watson_24_7_Embedded_Service',buttonId:'Watson_24_7_Chat_Bot'})" id="epwf_10017_chatwithus" data-galink-type="chat">chat with us.</a>
            so we can help.</p>
        </div>
            
    </div>
    <div  v-if="HCDE128" id="paymentError">
        <div class="errorBox">
            <h2 class="hcde128-err-msg">Whoops! Something didn't go quite as expected.</h2>				
            <p class="hcde128-err-text">No payment was submitted. Would you like to 
                <a :href="PayBillViewURL" target="_blank" id="paybillduplicatesession">start over and try again</a>?</p>
        </div>
    </div>
    <div  v-if="HCDE400" id="paymentError">
        <div class="pleaseEnterEmailMessage">
            <h2 data-test="autopayFailure">Sorry. Your payment was not completed.</h2>
            <p>It looks like maybe you've already made an identical payment today.</p>
            
            <p style="margin-bottom:0;">If you're intentionally trying to make an extra payment on your CenturyLink account, you'll have to 
                <a :href="PayBillViewURL" target="_blank" id="tryagain"><strong>try again</strong></a>
                using a different payment method or for a different dollar amount.</p>					
        </div>
    </div>
    <div  v-if="HCDE403" id="paymentError">
        <div class="pleaseEnterEmailMessage">
            <h2>Sorry. Your payment cannot be completed.</h2>
            <p>It looks like you've made several payments lately.</p>
            
            <p style="margin-bottom:0;">We only allow up to 5 payments every 30 days per CenturyLink account. Please 
                <a :href="PaymentHistoryMya" target="_blank" id="paymenthistory"><strong>review your payment history</strong></a> 
                and try again at a later date.</p>					
        </div>
    </div>
    <div v-if="AP003" id="paymentError">
        <div class="errorBox" data-galink-type="chat">
            <h2>Sorry &#45; this account is temporarily restricted from managing AutoPay.</h2>
            <p>This account is temporarily suspended. Once your service is restored, you'll be able to resume managing AutoPay on this account.</p>                    
        </div>
    </div>
    <div v-if="HCDE111" id="paymentError">
        <div class="errorBox">
            <h2>We apologize, this account is restricted from removing AutoPay.</h2>
            <p>If you need help with your current AutoPay settings, please
                <span v-if="isBillerEnsemble">
                    <a href="javascript:startChat({skillName:'Watson_24_7_Embedded_Service',buttonId:'Watson_24_7_Chat_Bot'})" id="unenrollment_restricted_chatwithus" data-galink-type="chat">chat with us.</a>
                </span>
                <span v-if="!isBillerEnsemble">
                    <a href="javascript:startChat({skillName:'Watson_24_7_Embedded_Service',buttonId:'Watson_24_7_Chat_Bot'})" id="unenrollment_restricted_chatwithus" data-galink-type="chat">chat with us.</a>
                </span>
            </p>
        </div>
    </div>
    <div id="paymentError" class="paymentError" v-if="!HCDE400 && !HCDE403 && !HCDE128 && !HCDE110 && !HCDE10017 && !HCDE011 && !AP003 && !HCDE111 && (timeOutValue !== 'timeOut')">
        <h2>Our apologies, this page is experiencing technical issues.</h2>
    </div>
    </div>
</div>
   
</template>
<script>
import constants from "../../js/constants";
import { GET_DATA, SET_DATA } from "../../store/sessioncontants";
import { SET_DATA as local_setdata} from "../../store/storecontants";
import axios from "axios";
import links from "../../js/links"
export default {
    name: 'FailureAutopay',
    data() {
        return {
            HCDE400: null,
            HCDE403: null,
            HCDE128: null,
            HCDE110: null,
            HCDE011: null,
            HCDE10017 : null,
            AP003: null,
            HCDE111: null,
            errorCode: "",
            accountNumber: "",
            isBillerEnsemble: false,
            token: null,
            EmailPreferencesURL: links.EmailPreferencesURL,
            PayBillViewURL: links.PayBillViewURL,
            PaymentHistoryMya: links.PaymentHistoryMya
        };
    },
    props: ['timeOutValue'],
    methods: {
        getPaymentFailureDetails() {
            if (this.errorCode != null || this.errorCode != "") {
                if (this.errorCode === "HCDE400") {
                    this.HCDE400 = true;
                    this.HCDE403 = false;
                    this.HCDE128 = false;
                    this.HCDE110 = false;
                    this.HCDE10017 = false;
                    this.HCDE011 = false;
                    this.AP003 = false;
                    this.HCDE111 = false;
                } else if (this.errorCode === "HCDE403") {
                    this.HCDE400 = false;
                    this.HCDE403 = true;
                    this.HCDE128 = false;
                    this.HCDE110 = false;
                    this.HCDE10017 = false;
                    this.HCDE011 = false;
                    this.AP003 = false;
                    this.HCDE111 = false;
                } else if (this.errorCode === "HCDE128") {
                    this.HCDE400 = false;
                    this.HCDE403 = false;
                    this.HCDE128 = true;
                    this.HCDE110 = false;
                    this.HCDE10017 = false;
                    this.HCDE011 = false;
                    this.AP003 = false;
                    this.HCDE111 = false;
                } else if (this.errorCode === "HCDE110") {
                    this.HCDE400 = false;
                    this.HCDE403 = false;
                    this.HCDE128 = false;
                    this.HCDE110 = true;
                    this.HCDE10017 = false;
                    this.HCDE011 = false;
                    this.AP003 = false;
                    this.HCDE111 = false;
                } else if (this.errorCode === "HCDE10017") {
                    this.HCDE400 = false;
                    this.HCDE403 = false;
                    this.HCDE128 = false;
                    this.HCDE110 = false;
                    this.HCDE10017 = true;
                    this.HCDE011 = false;
                    this.AP003 = false;
                    this.HCDE111 = false;
                } else if (this.errorCode === "HCDE011") {
                    this.HCDE400 = false;
                    this.HCDE403 = false;
                    this.HCDE128 = false;
                    this.HCDE110 = false;
                    this.HCDE10017 = false;
                    this.HCDE011 = true;
                    this.AP003 = false;
                    this.HCDE111 = false;
                } else if (this.errorCode === "AP003") {
                    this.HCDE400 = false;
                    this.HCDE403 = false;
                    this.HCDE128 = false;
                    this.HCDE110 = false;
                    this.HCDE10017 = false;
                    this.HCDE011 = false;
                    this.AP003 = true;
                    this.HCDE111 = false;
                } else if (this.errorCode === "HCDE111") {
                    this.HCDE400 = false;
                    this.HCDE403 = false;
                    this.HCDE128 = false;
                    this.HCDE110 = false;
                    this.HCDE10017 = false;
                    this.HCDE011 = false;
                    this.AP003 = false;
                    this.HCDE111 = true;
                }else {
                    this.HCDE400 = false;
                    this.HCDE403 = false;
                    this.HCDE128 = false;
                    this.HCDE110 = false;
                    this.HCDE10017 = false;
                    this.HCDE011 = false;
                    this.AP003 = false;
                    this.HCDE111 = false;
                }
            }
        },
        async setLogger() {
            let params = {}
            if (this.errorCode) {
                params = {
                    logString: "AutoPay failure for account number " + this.accountNumber + ", HCDE Error code " + this.errorCode + this.errorMessage
                };
            } else {
                params = {
                    logString: "AutoPay failure for account number " + this.accountNumber
                };
            }
            try {
                await axios.get(constants.PaymentFailureCallForOrchestrator, { params });
            } catch (error) {
                console.log(error);
            }
        }
    },
    mounted() {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'ap_failure',
            flow: {
                name: 'autopay',
                step: 'failure'
            }
        });

        let attributesData = new Map();
        let localData = new Map();
        localData.set('showLoading', true);
        this.$store.dispatch(local_setdata, {attributes:localData});
        let result = this.$route.query.ErrorCode ? this.$route.query.ErrorCode: '';
        this.errorCode = result.includes("-") ? result.replace(/-/g, "") : result;
        this.accountNumber = this.$store.getters[GET_DATA]("accountNumber");
        this.isBillerEnsemble = this.$store.getters[GET_DATA]("isBillerEnsemble");
        this.token = this.$store.getters[GET_DATA]("token");
        switch(this.errorCode) {
            case 'HCDE111':
            this.errorMessage = ', account is UNENROLL RESTRICTED';
            break;
            case 'AP003':
            this.errorMessage = ', account is SUSPENDED';
            break;
            case 'HCDE110':
            this.errorMessage = ', account is SESSION TIMED OUT';
            break;
            case 'HCDE011':
            this.errorMessage = ', account is MAX RETRY LIMIT ERROR';
            break;
        }
        if (this.accountNumber) {
            this.setLogger();
            localData.set('showLoading', false);
            this.$store.dispatch(local_setdata, {attributes:localData});
        } else {
            localData.set('showLoading', false);
            this.$store.dispatch(local_setdata, {attributes:localData});
        }
        this.getPaymentFailureDetails();
    }
}
</script>

<style scoped>
#paymentError {
    margin: 0 auto 20px;
}
.errorBox {
    text-align: left;
}
#paymentError h2 {
    text-align: left;
    margin: 20px 0;
    color: #14161a;
    font-size: 24px;
    font-weight: normal;
}
#paymentError p {
    color: #000000;
    margin-bottom: 15px;
    font-size: 16px;
}
div.pleaseEnterEmailMessage {
    background-position: 4px 23px;
}
#paymentError a {
    color: #0047bb;
}
    
</style>