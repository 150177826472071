<template>
  <div class="container d-flex justify-content-center">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-lg-12 col-xs-12">
        <div v-if="HCDE400">
          <div id="paymentError" class="qbp-hcde-error-wrapper">
            <h1>CenturyLink - QuickBillPay error</h1>
            <h3>Sorry. Your payment was not completed.</h3>
            <p>
              It looks like maybe you've already made an identical payment
              today.
            </p>
            <p>
              If you're intentionally trying to make an extra payment on your
              CenturyLink account, you'll have to
              <a href="/qbp/identify" id="tryagainhcde400">try again</a>
              using a different payment method or for a different dollar amount.
            </p>
          </div>
        </div>
        <div v-if="HCDE403">
          <h1>CenturyLink - QuickBillPay error</h1>
          <div id="paymentError" class="qbp-hcde-error-wrapper">
            <h3>Sorry. Your payment cannot be completed.</h3>
            <p>It looks like you've made several payments lately.</p>
            <p>
              We only allow up to 5 payments every 30 days per CenturyLink
              account.
              <b>
              <a :href="this.signInURL" id="signinhcde403">Sign into My CenturyLink</a></b>
              to review your payment history and try again at a later date.
            </p>
          </div>
        </div>
        <div v-if="HCDE128">
          <div id="paymentError">
            <h1>CenturyLink - QuickBillPay error</h1>
            <div class="outside outside2 hcde128-outside-padding">
              <div class="inside">
                <div class="errorBox">
                  <h2 class="hcde128-err-msg">
                    Whoops! Something didn't go quite as expected.
                  </h2>
                  <p class="hcde128-err-text">
                    No payment was submitted. Would you like to
                    <a href="/qbp/identify" id="qbpduplicatesession"
                      >start over and try again</a
                    >?
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="(HCDE110) || (sessionTimeOut === 'timeOut')">
          <div id="paymentError">
            <div class="outside outside2">
              <div class="inside">
                <div class="pleaseEnterEmailMessage">
                  <h1>Your session has timed out.</h1>
                  <p>
                    For the security of your account, your session was ended due
                    to inactivity.
                  </p>
                  <p style="margin-bottom: 0">
                    Would you like to
                    <a href="/qbp/identify" id="startoverandtryagain">
                      start over and try again</a
                    >?
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="paymentError" v-if="!HCDE400 && !HCDE403 && !HCDE128 && !HCDE110 && (sessionTimeOut !== 'timeOut')">
          <h1>Our apologies, this page is experiencing technical issues.</h1>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import constants from "../js/constants";
import { GET_DATA, SET_DATA } from "../store/storecontants";
import axios from "axios";
import { pii } from "../js/pii";

export default {
  name: "FailureOfPayment",
  data() {
    return {
      signInURL: process.env.VUE_APP_EAM_DOMAIN+ '/eam/entryPoint.do',
      HCDE400: null,
      HCDE403: null,
      HCDE128: null,
      HCDE110: null,
      urlRouter: "",
      accountNumber: ""
    };
  },
  computed: {
    sessionTimeOut() {
      return this.$route.params.showTimeout;
    }
  },
  mounted() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'qbp_failure_of_payment',
      flow:{
        name: 'qbp',
        step: 'failure_of_payment_page'
      },
    });

    let attributesData = new Map();
    attributesData.set('showLoading', true);
    this.$store.dispatch(SET_DATA, {attributes:attributesData});
    let result = this.$route.query["amp;ErrorCode"] ? this.$route.query["amp;ErrorCode"] : this.$route.query.ErrorCode
    this.urlRouter = result.includes("-") ? result.replace(/-/g, "") : result;
    this.accountNumber = this.$store.getters[GET_DATA]('account');
    if (this.accountNumber) {
        this.setLogger();
        attributesData.set('showLoading', false);
        this.$store.dispatch(SET_DATA, {attributes:attributesData});
    } else {
        attributesData.set('showLoading', false);
        this.$store.dispatch(SET_DATA, {attributes:attributesData});
    }
    this.getPaymentFailureDetails();
    attributesData.set('showLoading', false);
    this.$store.dispatch(SET_DATA, {attributes:attributesData});
  },
  methods: {
    getPaymentFailureDetails() {
      if (this.urlRouter != null || this.urlRouter != "") {
        if (this.urlRouter === "HCDE400") {
          this.HCDE400 = true;
          this.HCDE403 = false;
          this.HCDE128 = false;
          this.HCDE110 = false;
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "qbp_error",
            accountNumber: pii(this.accountNumber),
            errorName: "HCDE400 Duplicate payment",
            flow: {
              name: "qbp",
              step: "payment flow",
            },
          });
        } else if (this.urlRouter === "HCDE403") {
          this.HCDE400 = false;
          this.HCDE403 = true;
          this.HCDE128 = false;
          this.HCDE110 = false;
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "qbp_error",
            accountNumber: pii(this.accountNumber),
            errorName: "HCDE403 Reached Maximum number of card payment with in 30days",
            flow: {
              name: "qbp",
              step: "payment flow",
            },
          });
        } else if (this.urlRouter === "HCDE128") {
          this.HCDE400 = false;
          this.HCDE403 = false;
          this.HCDE128 = true;
          this.HCDE110 = false;
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "qbp_error",
            accountNumber: pii(this.accountNumber),
            errorName: "HCDE128 session timeout",
            flow: {
              name: "qbp",
              step: "payment flow",
            },
          });
        } else if (this.urlRouter === "HCDE110") {
          this.HCDE400 = false;
          this.HCDE403 = false;
          this.HCDE128 = false;
          this.HCDE110 = true;
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "qbp_error",
            accountNumber: pii(this.accountNumber),
            errorName: "HCDE110 HCDE timeout",
            flow: {
              name: "qbp",
              step: "payment flow",
            },
          });
        } else {
          this.HCDE400 = false;
          this.HCDE403 = false;
          this.HCDE128 = false;
          this.HCDE110 = false;
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "qbp_error",
            accountNumber: pii(this.accountNumber),
            errorName: this.urlRouter,
            flow: {
              name: "qbp",
              step: "payment flow",
            },
          });
        }
      }
    },
    async setLogger() {
      let params = {}
      if (this.urlRouter) {
          params = {
            logString :  "qbp Payment failure for accountNumber=" + this.accountNumber + " HCDE ErrorCode=" + this.urlRouter
          };
      } else {
          params = {
            logString: "qbp Payment failure for accountNumber=" + this.accountNumber
          };
      }
      try {
        await axios.get(constants.PaymentFailureCallForOrchestrator, { params });
      } catch (error) {
        console.log(error);
      }
    }
  },
  updated() {
      let attributesData = new Map();
      attributesData.set('showLoading', true);
      this.$store.dispatch(SET_DATA, {attributes:attributesData});
      attributesData.set('showLoading', false);
      this.$store.dispatch(SET_DATA, {attributes:attributesData});
  },
};
</script>
<style scoped>
#paymentError h1 {
    color: black;
    font-size: 1.5em;
    font-weight: bold;
    padding-bottom: 0em;
    margin: 0em 0;
    border-bottom: none;
}
#paymentError {
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .qbp-hcde-error-wrapper {
    background-position: 5px 15px;
  }  
}
</style>