<template>
    <div class="container" v-if="paymentAmount !== null && paymentDate !== null">
        <div class="row">
            <!-- <div v-if="paymentAmount !== null && paymentDate !== null"> -->
            <div>
                <h1 class="text-start text-decor">Make a payment</h1>
                <div v-if="paymentAmount > '0.00'" class="paymentDetailBlock text-start">
                    <div class="row text-decor">
                        <div class="col-1"><img src="../../images/Dollar@2x.png" alt="dollar icon" class='icon-circle' /></div>
                        <div class="col-11">
                            <div class="row icon-text-margin">
                                <div class="col-xs-12 text-start detail-margin">ACCOUNT BALANCE</div>
                                <div class="col-xs-12 text-start detail-margin">$ {{ paymentAmount }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="paymentAmount > '0.00'" class="paymentDetailBlock text-start">
                    <div class="row text-decor">
                        <div class="col-1"><img src="../../images/FullCalendar.png" alt="fullCalendar" class='icon-circle' /></div>
                        <div class="col-11">
                            <div class="row icon-text-margin">
                                <div class="col-xs-12 text-start detail-margin">DUE ON</div>
                                <div class="col-xs-12 text-start detail-margin">{{ paymentDate }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            <!-- </div> -->
        
        <div v-if="this.epwf" class="row">
            <div v-if="iframeSession !== null">
                <div class="bill-iframe">
                    <iframe id="postBillIframe" :src="this.postBillIframeUrl" width="100%" title="postLoginIframe"
                         style="height: 850px; border: none; overflow: hidden;" :style="{height: postBillIframeHeight + 'px'}" 
                         :onload="this.getPostBillResolution">
                        <p>Your browser does not support iframes.</p>
                    </iframe>
                </div>
            </div>
            <div v-else-if="iframeSession === null && !showLoader">
                <div class="text-start bill-iframe">
                    <post-bill-iframe-error/>
                </div>
            </div>
        </div>
        <div v-if="this.brim" class="paymentDetailBlock" id="brimButtonBlock">
            <button v-if="this.cardDetails || this.bankDetails" type="button" class="pb-payment-cta" @click="savedOption">Pay With A Saved Payment Method</button>
            <button v-if="this.isAchAllowed" type="button" class="pb-payment-cta" @click="bankOption">Pay With Bank Account</button>
            <button v-if="this.isCardAllowed" type="button" class="pb-payment-cta" @click="cardOption">Pay With Credit or Debit Card</button>
        </div>
    </div>
    </div>
    </div>
    <div class="container" v-else-if="paymentAmount == null && paymentDate == null && !showLoader">
        <div class="row">
            <div class="text-start bill-iframe">
                <post-bill-iframe-error/>
            </div>
        </div>
    </div>
    <div id="myModal" class="modal fade" role="dialog">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header no-border">
                    <h5 v-if="this.bankOptionTitle" class="modal-title">Enter bank account info</h5>
                    <h5 v-if="this.cardOptionTitle" class="modal-title">Enter card info</h5>
                    <h5 v-if="this.savedOptionTitle" class="modal-title">Select a saved payment method</h5>
					<button type="button" class="btn" @click="ModalClose()" aria-label="Close"> 
                        <img src="../../images/svg/icon-navigation-close_24px.svg" alt="outline-info"/>
                    </button>
				</div>
                <div v-if="this.cardImg" id="card-img-section">
                    <img class="visa-img" src="../../images/visa.svg" alt="visa">
                    <img class="mastercard-img" src="../../images/mastercard.svg" alt="mastercard">
                    <img class="discover-img" src="../../images/discover.svg" alt="discover">
                </div>
                <div class="modal-body">
                    <div class="hcde-comp">
                        <hcde-tokenization v-if="this.hcdeReady" :env="this.env" clientId="MYA_PAY" :sessionId="this.sessionId" callbackFunction="showCardPopup" callbackField="myTokenVar" statusFunction="tokenStatus" statusField="statusVar" heartBeatFrequency="60" srcSysTransId="307902021108">Loading...</hcde-tokenization>
                        <hcde-wallet v-if="this.hcdeReadyWallet" :env="this.env" clientId="MYA_PAY" :sessionId="this.sessionId" callbackFunction="showBankPopup" callbackRefNumField="walletRefNum"  callbackInstrumentField="walletInstrumentId" statusFunction="walletStatus" statusField="statusVar" heartBeatFrequency="60" srcSysTransId="1234567890" >Loading...</hcde-wallet>
                    </div>
                    <div v-if="this.savedOptionTitle">
                        <div v-for="(option, index) in cardDetails" :key="index" :class="option.cardType" class="wallet-cards wallet-box" tabindex="0">
                            <div class="img-content-container" @click="savedCardOption(option.maskedAccountNumber, option.cardExpirationDate, option.cardBillingZipCd, option.cardType, option.instrumentId)">
                                <div class="img-card"></div>
                                <p><span>{{option.cardType}}</span> <span>{{option.maskedAccountNumber}}</span></p>
                            </div>
                        </div>
                        <div v-for="(option, index) in bankDetails" :key="index" :class="option.bankAccountType" class="wallet-bank wallet-box" tabindex="0">
                            <div class="img-content-container" @click="savedBankOption(option.bankRoutingNumber, option.bankAccountNumber, option.bankAccountType, option.instrumentId)">
                                <div class="img-bank"></div>
                                <p><span>{{option.bankAccountType}}</span> <span>{{option.maskedAccountNumber}}</span></p>
                            </div>
                        </div>
                    </div>
                    <div v-if="this.savedWalletFooter" class="d-flex mt-4 mb-2">
                        <button v-if="this.savedBank" id="editCardBtn" type="button" class="btn btn-primary btn-lg text-center" @click="savedBankPopup()">Submit</button>
                        <button v-if="this.savedCard" id="editCardBtn" type="button" class="btn btn-primary btn-lg text-center" @click="savedCardPopup()">Submit</button>
                    </div>
                </div>
			</div>
		</div>
	</div>
  <div id="confirmationModal" class="modal large" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content content-size">
        <div class="modal-header no-border">
          <p class="modal-title-1">I (we) hereby authorize a payment debit entry to my (our) Credit/Debit/Share Draft account.
            I (we) understand that if the payment under this authorization is returned or otherwise dishonored,
            I (we) will promptly remit the payment due plus any fees due under my (our) account.</p>
        </div>
        <div class="modal-body">
          <div class="hcde-comp">
            <p>Please verify the information below. If anything looks incorrect, please: <button type="button" class="cancel-button" @click="ClosePopup()">Make Changes</button>.</p>
          </div>
          <div class="align-center">
            <div class="flex-component">
              <div class="flex-entry">
                <p class="flex-label">Bill Payment:</p><p class="flex-span"> USD ${{ Number(this.paymentAmountEntered).toFixed(2) }}</p>
              </div>
              <div v-if="this.selectedOption === 'Card' || this.selectedOption === 'SavedCard'" class="flex-entry">
                <p class="flex-label">Convenience Fee:</p><p class="flex-span"> USD ${{ Number(this.convenienceFeeAmtCard).toFixed(2) }}</p>
              </div>
              <div v-if="(this.selectedOption === 'Card' || this.selectedOption === 'SavedCard') && this.totalCardPayment" class="flex-entry">
                <p class="flex-label">Total Payment:</p><p class="flex-span"> USD ${{ this.totalCardPayment }}</p>
              </div>
              <div v-if="this.selectedOption === 'Card' || this.selectedOption === 'SavedCard'" class="flex-entry">
                <p class="flex-label">Payment Date:</p><p class="flex-span"> {{ this.currentPaymentDate() }}</p>
              </div>
              <div v-if="this.selectedOption === 'Card' || this.selectedOption === 'SavedCard'" class="flex-entry">
                <p class="flex-label">Payment Method:</p><p class="flex-span"> Card {{ this.enteredCardNumber}}</p>
              </div>

              <div v-if="this.selectedOption === 'Bank' || this.selectedOption === 'SavedBank'" class="flex-entry">
                <p class="flex-label">Convenience Fee:</p><p class="flex-span"> USD ${{ Number(this.convenienceFeeAmtAch).toFixed(2) }}</p>
              </div>
              <div v-if="(this.selectedOption === 'Bank' || this.selectedOption === 'SavedBank') && this.totalBankPayment" class="flex-entry">
                <p class="flex-label">Total Payment:</p><p class="flex-span"> USD ${{ this.totalBankPayment }}</p>
              </div>
              <div v-if="this.selectedOption === 'Bank' || this.selectedOption === 'SavedBank'" class="flex-entry">
                <p class="flex-label">Payment Date:</p><p class="flex-span"> {{ this.currentPaymentDate() }}</p>
              </div>
              <div v-if="this.selectedOption === 'Bank' || this.selectedOption === 'SavedBank'" class="flex-entry">
                <p class="flex-label">Payment Method:</p><p class="flex-span"> Bank {{ this.enteredAccountNumber}}</p>
              </div>
            </div>
          </div>
          <div v-if="this.selectedOption === 'Card' || this.selectedOption === 'SavedCard'" class="hcde-comp">
            <p class="alert-content">By clicking "Submit Payment", you are authorizing your financial institution to charge your account <span v-if="this.totalCardPayment">USD  ${{ this.totalCardPayment }}</span>.
              This included a ${{ Number(this.convenienceFeeAmtCard).toFixed(2) }} convenience fee for using this service.</p>
          </div>
          <div v-if="this.selectedOption === 'Bank' || this.selectedOption === 'SavedBank'" class="hcde-comp">
            <p class="alert-content">*Convenience fee discount for using cash or cash equivalent payment method.</p>
            <p class="alert-content">By clicking "Submit Payment", you are authorizing your financial institution to charge your account <span v-if="this.totalBankPayment">USD  ${{ this.totalBankPayment }}</span>.</p>
          </div>
          <div class="flex-component-button content-spacing">
            <div class="flex-entry-button">
              <button class="Submit-Button" @click="ClosePopup()">Cancel</button>
            </div>
            <div class="flex-entry-button">
              <div v-if="this.selectedOption === 'Card'" class="flex-entry">
                <button class="Submit-Button" @click="createCardPaymentPlan(this.myTokenVar)">Submit</button>
              </div>
              <div v-if="this.selectedOption === 'Bank'" class="flex-entry">
                <button class="Submit-Button" @click="createBankPaymentPlan(this.walletInstrumentId)">Submit</button>
              </div>
              <div v-if="this.selectedOption === 'SavedCard' || this.selectedOption === 'SavedBank'" class="flex-entry">
                <button class="Submit-Button" @click="createWalletPaymentPlan()">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GET_DATA, SET_DATA } from '../../store/sessioncontants'
import { SET_DATA as local_setdata} from "../../store/storecontants";
import { Modal } from "bootstrap/dist/js/bootstrap.bundle";
import axios from 'axios'
import constants from '../../js/constants'
import postBillIframeError from '../ErrorMessages/postBillIframeError.vue'
import links from '../../js/links'
import hcdeStatus from "../../js/hcdestatus";
export default {
    name: 'PostLoginBillView',
    components: {
        postBillIframeError
    },
    data() {
        return {
            accountNumber: null,
            walletRefNo: null,
            emailAddress: null,
            iframeSession: null,
            postBillIframeUrl: null,
            sessionId: null,
            paymentAmount: null,
            paymentDate: null,
            showLoader: true,
            isAutopayEnrolled: false,
            postBillIframeHeight: 850,
            postBillIframeChange: 0,
            brimOn: false,
            brim: false,
            env: null,
            epwf: false,
            WalletJSEncrypt: null,
            hcdeWalletServiceApi: null,
            hcdeTokenServiceApi: null,
            hcdeWalletComponent: null,
            hcdeTokenComponent: null,
            bankOptionTitle: false,
            cardOptionTitle: false,
            cardImg: false,
            hcdeReady: false,
            hcdeReadyWallet: false,
            cardDetails: null,
            bankDetails: null,
            savedOptionTitle: false,
            formattedDate: '',
            firstName: null,
            lastName: null,
            streetAddress1: null,
            streetAddress2: null,
            city: null,
            stateProvince: null, 
            postalCode: null,
            instrumentId: null,
            savedWalletFooter: false,
            savedBank: false,
            savedCard: false,
            paymentProcessedDate: null,
            isCardAllowed: false,
            isAchAllowed: false,
            convenienceFeeAmtAch: null,
            convenienceFeeAmtCard: null,
            selectedOption: null,
            paymentAmountEntered: null,
            enteredAccountNumber: null,
            enteredCardNumber: null,
            myTokenVar: null,
            walletInstrumentId: null,
            totalCardPayment: null,
            totalBankPayment: null,
        }
    },
    updated() {
    this.isBrimOn = process.env.VUE_APP_BRIM;
    if(this.isBrimOn == 'true'){
        this.createHcdeTagListener()   
        } 
    },
    mounted() {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'lbp_start',
            flow:{
                name: 'logged in bill pay',
                step: 'start'
            },
        });
        localStorage.clear();
        let localData = new Map();
        localData.set('showLoading', true);
        this.$store.dispatch(local_setdata, {attributes:localData});
        this.accountNumber = this.$store.getters[GET_DATA]('accountNumber'),
        this.walletRefNo = this.$store.getters[GET_DATA]('walletRefNo'),
        this.emailAddress = this.$store.getters[GET_DATA]('emailAddress')
        this.convenienceFeeAmtAch = JSON.parse(this.$store.getters[GET_DATA]('convenienceBankFee'));
        this.convenienceFeeAmtCard = JSON.parse(this.$store.getters[GET_DATA]('convenienceCardFee'));
        this.getPostBillIframeDetail();
        this.paymentModal = new Modal(document.getElementById('myModal'),{
            backdrop: 'static',
            keyboard: false
        });
        this.popupModal = new Modal(document.getElementById('confirmationModal'),{
          backdrop: 'static',
          keyboard: false
        });
        this.brimOn = process.env.VUE_APP_BRIM;
        this.env = process.env.VUE_APP_env;
        this.currentDate();
        if(this.brimOn == "true"){
            this.WalletJSEncrypt = links.WalletJSEncrypt;
            this.hcdeWalletServiceApi = links.hcdeWalletServiceApi;
            this.hcdeTokenServiceApi = links.hcdeTokenServiceApi;
            this.hcdeWalletComponent = links.hcdeWalletComponent;
            this.hcdeTokenComponent = links.hcdeTokenComponent;
            this.brim = true;
            this.epwf = false;
            this.initiate();
        }else {
            this.brim = false;
            this.epwf = true;
        }
        // eslint-disable-next-line
        const self = this;
        window.showCardResponse = function (){
            self.createCardPaymentPlan(window.myTokenVar);
        };
        // eslint-disable-next-line
        window.showBankResponse = function(){
            self.createBankPaymentPlan(window.walletInstrumentId);
        };
        // eslint-disable-next-line
        window.tokenStatus = function(){
            hcdeStatus.showTokenStatus(window.statusVar);
            self.checkStatus(window.statusVar);
        };
        // eslint-disable-next-line
        window.walletStatus = function(){
            hcdeStatus.showWalletStatus(window.statusVar);
            self.checkStatus(window.statusVar);
        };
      window.showBankPopup = function(){
        self.createBankPopupModal(window.walletInstrumentId);
      };
      window.showCardPopup = function(){
        self.createCardPopupModal(window.myTokenVar);
      };

        const date = new Date();
        this.paymentProcessedDate = this.formatPaymentProcessedDate(date);
    },
    beforeUnmount() {
        // It's a good practice to clean up by removing the function when the component is destroyed
        delete window.showBankResponse;
        delete window.showCardResponse;
        delete window.tokenStatus;
        delete window.walletStatus;
    },
    methods: {
        formatPaymentProcessedDate(date){
            const day = date.toLocaleString("en-us", {
                day: "2-digit",
            });
            const month = date.toLocaleString("en-us", {
                month: "2-digit",
            });
            const year = date.getFullYear();
            const formattedDate = year + '-' + month + '-' + day;
            return formattedDate;
        },
          async createBankPopupModal(token) {
          this.walletInstrumentId = token;
          let paymentAmount = null;
          let inputtedPaymentAmount = document.getElementById('PaymentAmount');
          paymentAmount = inputtedPaymentAmount.value;
          this.paymentAmountEntered = paymentAmount.replace('$', '');
          let bankAccountNum = document.getElementById('bankAccountNumber');
          this.enteredAccountNumber = bankAccountNum.value;
          const num1 = Number(this.paymentAmountEntered);
          const num2 = Number(this.convenienceFeeAmtAch);
          const total = num1+num2;
          this.totalBankPayment = Number(total).toFixed(2);
          this.popupModal.show();
          this.selectedOption = 'Bank'
        },
        async createCardPopupModal(token) {
          this.myTokenVar = token;
          let paymentAmount = null;
          let inputtedPaymentAmount = document.getElementById('PaymentCrAmount');
          paymentAmount = inputtedPaymentAmount.value;
          this.paymentAmountEntered = paymentAmount.replace('$', '');
          let CardNumber = document.getElementById('hcdeCardNumber');
          this.enteredCardNumber = CardNumber.value;
          const num1 = Number(this.paymentAmountEntered);
          const num2 = Number(this.convenienceFeeAmtCard);
          const total = num1+num2;
          this.totalCardPayment = Number(total).toFixed(2);
          this.popupModal.show();
          this.selectedOption = 'Card'
        },
        async savedCardPopup() {
          let CardNumber = document.getElementById('hcdeCardNumber');
          this.enteredCardNumber = CardNumber.value;
          let paymentAmount = null;
          let inputtedPaymentAmount = document.getElementById('PaymentCrAmount');
          paymentAmount = inputtedPaymentAmount.value;
          this.paymentAmountEntered = paymentAmount.replace('$', '');
          const num1 = Number(this.paymentAmountEntered);
          const num2 = Number(this.convenienceFeeAmtCard);
          const total = num1+num2;
          this.totalCardPayment = Number(total).toFixed(2);
          this.popupModal.show();
          this.selectedOption = 'SavedCard'
        },
        async savedBankPopup() {
          let bankAccountNum = document.getElementById('bankAccountNumber');
          this.enteredAccountNumber = bankAccountNum.value;
          let paymentAmount = null;
          let inputtedPaymentAmount = document.getElementById('PaymentAmount');
          paymentAmount = inputtedPaymentAmount.value;
          this.paymentAmountEntered = paymentAmount.replace('$', '');
          const num1 = Number(this.paymentAmountEntered);
          const num2 = Number(this.convenienceFeeAmtAch);
          const total = num1+num2;
          this.totalBankPayment = Number(total).toFixed(2);
          this.popupModal.show();
          this.selectedOption = 'SavedBank'
        },
        async createCardPaymentPlan(token){
            let paymentAmount = null;
            let inputtedPaymentAmount = document.getElementById('PaymentCrAmount');
            paymentAmount = inputtedPaymentAmount.value;
            try {
                let params = {
                    accountNumber: this.accountNumber,
                    accountType: 'CON',
                    firstName: this.firstName,
                    lastName: this.lastName,
                    businessName: null,
                    emailId: this.emailAddress,
                    paymentAmount: paymentAmount.replace('$', ''),
                    paymentProcessedDate: this.paymentProcessedDate,
                    address: {
                        streetAddress1: this.streetAddress1,
                        streetAddress2: this.streetAddress2,
                        city: this.city,
                        stateProvince: this.stateProvince,
                        postalCode: this.postalCode,
                        addressType: null,
                        countryCd: 'US'
                    },
                    cardToken: token,
                    bankToken: null,
                    walletReferenceNumber: null,
                    instrumentId: null
                }
                const res = await axios.post(constants.BrimPostBillCreate, params);
                if (res.data.transactionStatus === 'S') {
                    let attributesData = new Map();
                    attributesData.set('confirmationNumber', res.data.masterPaymentId);
                    this.$store.dispatch(SET_DATA, {attributes:attributesData});
                    this.ModalClose();
                    this.$router.push({
                        name: "PostLoginBillSuccess",
                        params: {},
                    });
                }  else if (res.data.transactionStatus === 'F') {
                    let attributesData = new Map();
                    attributesData.set('errorCd', res.data.errorInfo.errorCd);
                    this.$store.dispatch(SET_DATA, {attributes:attributesData});
                    this.ModalClose();
                    this.$router.push({
                        name: "PostLoginBillFailure",
                        params: {},
                    });
                }
            } catch (error) {
                console.log(error);
                this.ModalClose();
                this.$router.push({
                    name: "PostLoginBillFailure",
                    params: {},
                });
            }
        },
        async createBankPaymentPlan(token){
            let paymentAmount = null;
            this.ClosePopup();
            let attributesData = new Map();
            attributesData.set('showLoading', true);
            this.$store.dispatch(SET_DATA, { attributes: attributesData });
            let inputtedPaymentAmount = document.getElementById('PaymentAmount');
            paymentAmount = inputtedPaymentAmount.value;
            try {
                let params = {
                    accountNumber: this.accountNumber,
                    accountType: 'CON',
                    firstName: this.firstName,
                    lastName: this.lastName,
                    businessName: null,
                    emailId: this.emailAddress,
                    paymentAmount: paymentAmount.replace('$', ''),
                    paymentProcessedDate: this.paymentProcessedDate,
                    address: {
                        streetAddress1: this.streetAddress1,
                        streetAddress2: this.streetAddress2,
                        city: this.city,
                        stateProvince: this.stateProvince,
                        postalCode: this.postalCode,
                        addressType: null,
                        countryCd: 'US'
                    },
                    cardToken: null,
                    bankToken: token,
                    walletReferenceNumber: null,
                    instrumentId: null
                }
                const res = await axios.post(constants.BrimPostBillCreate, params);
                if (res.data.transactionStatus === 'S') {
                    let attributesData = new Map();
                    attributesData.set('showLoading', false);
                    attributesData.set('confirmationNumber', res.data.masterPaymentId);
                    this.$store.dispatch(SET_DATA, {attributes:attributesData});
                    this.ModalClose();
                    this.$router.push({
                        name: "PostLoginBillSuccess",
                        params: {},
                    });
                }  else if (res.data.transactionStatus === 'F') {
                    let attributesData = new Map();
                    attributesData.set('showLoading', false);
                    attributesData.set('errorCd', res.data.errorInfo.errorCd);
                    this.$store.dispatch(SET_DATA, {attributes:attributesData});
                    this.ModalClose();
                    this.$router.push({
                        name: "PostLoginBillFailure",
                        params: {},
                    });
                }
            } catch (error) {
                console.log(error);
                this.ModalClose();
                let attributesData = new Map();
                attributesData.set('showLoading', false);
                this.$store.dispatch(SET_DATA, { attributes: attributesData });
                this.$router.push({
                    name: "PostLoginBillFailure",
                    params: {},
                });
            }
        },
        async createWalletPaymentPlan(){
            let paymentAmount = null;
            this.ClosePopup();
            let attributesData = new Map();
            attributesData.set('showLoading', true);
            this.$store.dispatch(SET_DATA, { attributes: attributesData });
            if(this.savedBank == true && this.savedCard == false){
                let inputtedPaymentAmount = document.getElementById('PaymentAmount');
                paymentAmount = inputtedPaymentAmount.value;
            } else if(this.savedCard == true && this.savedBank == false){
                let inputtedPaymentAmount = document.getElementById('PaymentCrAmount');
                paymentAmount = inputtedPaymentAmount.value;
            }
            try {
                let params = {
                    accountNumber: this.accountNumber,
                    accountType: 'CON',
                    firstName: this.firstName,
                    lastName: this.lastName,
                    businessName: null,
                    emailId: this.emailAddress,
                    paymentAmount: paymentAmount.replace('$', ''),
                    paymentProcessedDate: this.paymentProcessedDate,
                    address: {
                        streetAddress1: this.streetAddress1,
                        streetAddress2: this.streetAddress2,
                        city: this.city,
                        stateProvince: this.stateProvince,
                        postalCode: this.postalCode,
                        addressType: null,
                        countryCd: 'US'
                    },
                    cardToken: null,
                    bankToken: null,
                    walletReferenceNumber: this.walletRefNo,
                    instrumentId: this.instrumentId
                }
                const res = await axios.post(constants.BrimPostBillCreate, params);
                if (res.data.transactionStatus === 'S') {
                    let attributesData = new Map();
                    attributesData.set('showLoading', false);
                    attributesData.set('confirmationNumber', res.data.masterPaymentId);
                    this.$store.dispatch(SET_DATA, {attributes:attributesData});
                    this.ModalClose();
                    this.$router.push({
                        name: "PostLoginBillSuccess",
                        params: {},
                    });
                }  else if (res.data.transactionStatus === 'F') {
                    let attributesData = new Map();
                    attributesData.set('showLoading', false);
                    attributesData.set('errorCd', res.data.errorInfo.errorCd);
                    this.$store.dispatch(SET_DATA, {attributes:attributesData});
                    this.ModalClose();
                    this.$router.push({
                        name: "PostLoginBillFailure",
                        params: {},
                    });
                }
            } catch (error) {
                console.log(error);
                let attributesData = new Map();
                attributesData.set('showLoading', false);
                this.$store.dispatch(SET_DATA, { attributes: attributesData });
                this.ModalClose();
                this.$router.push({
                    name: "PostLoginBillFailure",
                    params: {},
                });
            }
        },
        checkStatus(status) {
            if(status.includes("EXPIRED")){
                this.ModalClose()
                this.$router.push({
                    name: "PostLoginBillFailure",
                    params: {showTimeout: 'timeOut'},
                });
            }
        },
        currentDate() {
            const current = new Date();
            const day = new Date(current).toLocaleString("en-us", {
                weekday: "long",
            });
            const month = current.toLocaleString("en-us", {
                month: "short",
            });
            const ordinal = this.get_nth_suffix(current);
            const today =
                "Today, " + day + ", " + month + " " + current.getDate() + ordinal;
            this.formattedDate = day + ", " + month + " " + current.getDate()
            return today;
        },
        currentPaymentDate() {
          const current = new Date();
          const options = {
            year: "numeric",
            month: "long",
            day: "2-digit",
          };
          return current.toLocaleDateString("en-US", options);
        },
        get_nth_suffix(date) {
            switch (date) {
                case 1:
                case 21:
                case 31:
                    return "st";
                case 2:
                case 22:
                    return "nd";
                case 3:
                case 23:
                    return "rd";
                default:
                    return "th";
            }
        },
        async initiate() {
            let localData = new Map();
            try{
                const params = {
                    billingAccountNumber: this.$store.getters[GET_DATA]('accountNumber'),
                    walletType: "PRF",
                    walletReferenceNumber: this.$store.getters[GET_DATA]('walletRefNo')
                };
                const res = await axios.post(constants.WalletInitiate, params);
                if(res.data) {
                    this.bankDetails = res.data.bankAcctDetails;
                    this.cardDetails = res.data.cardDetails;
                }else{
                    this.brimStatus = 'ERROR';
                    localData.set('showLoading', false);
                    this.$store.dispatch(local_setdata, {attributes:localData});
                }
            }catch (error) {
                console.log(error);
                this.brimStatus = 'ERROR';
                localData.set('showLoading', false);
                this.$store.dispatch(local_setdata, {attributes:localData});
            }
        },
        savedOption() {
            this.paymentModal.show();
            this.bankOptionTitle = false;
            this.cardOptionTitle = false;
            this.savedOptionTitle = true;
            this.savedWalletFooter = false;
            this.cardImg = false;
            this.hcdeReady = false;
            this.hcdeReadyWallet = false;
        },
        cardOption() {
            this.paymentModal.show();
            this.bankOptionTitle = false;
            this.cardOptionTitle = true;
            this.savedOptionTitle = false;
            this.savedWalletFooter = false;
            this.cardImg = true;
            this.hcdeReady = true;
            this.hcdeReadyWallet = false;
            const cardscripts = [
                this.hcdeTokenComponent				
            ];
            let formattedDateValue = this.formattedDate
            let bal = this.paymentAmount;
            cardscripts.forEach(script=>{
                let scriptFound = document.head.querySelector(`[src="${ script }"`) ? true : false;
                if (scriptFound) {
                    this.removeJSfile(script);
                }
                let tag = document.head.querySelector(`[src="${ script }"`);
                if (!tag) {
                    tag = document.createElement("script");
                    tag.setAttribute("src", script);
                    tag.setAttribute("type", 'text/javascript');
                    document.head.appendChild(tag);
                    tag.addEventListener('load', function () {
                        // eslint-disable-next-line no-undef
                        this.hcdeTagString = new HCDETokenServiceComponent();                  
                        const digitsOnlyRegExp = /^[0-9]*$/;

                        let hcdeCardNumCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(1)');
                        hcdeCardNumCon.setAttribute('id', 'hcdeCardNumberContainer');
                        hcdeCardNumCon.setAttribute('class', 'hcdeComponentInputContainer form-floating form-group col-12');

                        let hcdeCardNumLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(1) > label');
                        hcdeCardNumLabel.innerText = "";

                        let hcdeCardNumInput = document.getElementById('hcdeCardNumber');
                        hcdeCardNumInput.setAttribute('class', 'hcdeComponentTextBox form-control');
                        hcdeCardNumInput.setAttribute('placeholder', ' ');
                        hcdeCardNumInput.setAttribute('maxlength', '16');
                        hcdeCardNumInput.setAttribute('oninput', 'onInputCardNum()');
                        hcdeCardNumInput.addEventListener("keypress", e => {
                            if (!digitsOnlyRegExp.test(e.key)) {
                                e.preventDefault();
                            }
                        });

                        let newCardNumLabel = document.createElement('label');
                        newCardNumLabel.setAttribute('class', 'hcdeComponentLabel d-inline-block text-start text-truncate form-label col-12');
                        newCardNumLabel.setAttribute('id', 'hcdeCardNumberLabel');
                        newCardNumLabel.innerText = "Card Number";
                        hcdeCardNumCon.appendChild(newCardNumLabel);

                        let hcdeExpMonthCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(2)');
                        hcdeExpMonthCon.setAttribute('id', 'hcdeExpMonthContainer');
                        hcdeExpMonthCon.setAttribute('class', 'hcdeComponentInputContainer col-6 pe-1');

                        let hcdeExpMonthLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(2) > label');
                        hcdeExpMonthLabel.innerText = "";

                        let hcdeExpMonthInput = document.getElementById('hcdeCardExpiryMonth');
                        hcdeExpMonthInput.options[0].innerText = 'Expiration Month';
                        hcdeExpMonthInput.setAttribute('onchange', 'onInputExpMth()');

                        let hcdeExpYearCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(3)');
                        hcdeExpYearCon.setAttribute('id', 'hcdeExpYearContainer');
                        hcdeExpYearCon.setAttribute('class', 'hcdeComponentInputContainer col-6 ps-1');

                        let hcdeExpYearLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(3) > label');
                        hcdeExpYearLabel.innerText = "";

                        let hcdeExpYearInput = document.getElementById('hcdeCardExpiryYear');
                        hcdeExpYearInput.options[0].innerText = 'Expiration Year';
                        hcdeExpYearInput.setAttribute('onchange', 'onInputExpYr()');

                        let hcdeZipCodeCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(4)');
                        hcdeZipCodeCon.setAttribute('id', 'hcdeZipCodeContainer');
                        hcdeZipCodeCon.setAttribute('class', 'hcdeComponentInputContainer form-floating form-group col-6 pe-1');

                        let hcdeZipCodeLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(4) > label');
                        hcdeZipCodeLabel.innerText = "";

                        let hcdeZipCodeInput = document.getElementById('hcdeZipCode');
                        hcdeZipCodeInput.setAttribute('class', 'hcdeComponentTextBox form-control');
                        hcdeZipCodeInput.setAttribute('placeholder', ' ');
                        hcdeZipCodeInput.setAttribute('maxlength', '5');
                        hcdeZipCodeInput.setAttribute('oninput', 'onInputZipCode()');
                        hcdeZipCodeInput.addEventListener("keypress", e => {
                            if (!digitsOnlyRegExp.test(e.key)) {
                                e.preventDefault();
                            }
                        });

                        let newZipCodeLabel = document.createElement('label');
                        newZipCodeLabel.setAttribute('class', 'hcdeComponentLabel d-inline-block text-start text-truncate form-label col-12');
                        newZipCodeLabel.setAttribute('id', 'hcdeZipCodeLabel');
                        newZipCodeLabel.innerText = "Zip Code";
                        hcdeZipCodeCon.appendChild(newZipCodeLabel);

                        let hcdeCrAmount = document.createElement("div");
                        hcdeCrAmount.setAttribute('class', 'hcdeComponentInputContainer form-floating form-group col-12')
                        hcdeCrAmount.setAttribute('id', 'hcdeCrAmount');

                        let hcdeCrAmountLab = document.createElement("label");
                        hcdeCrAmountLab.setAttribute('for', 'PaymentCrAmount')
                        hcdeCrAmountLab.setAttribute('class', 'hcdeComponentLabel')

                        let hcdeCrAmountInput = document.createElement("input");
                        hcdeCrAmountInput.setAttribute('type', 'text')
                        hcdeCrAmountInput.setAttribute('name', 'paymentCrAmt')
                        hcdeCrAmountInput.setAttribute('class', 'hcdeComponentTextBox form-control')
                        hcdeCrAmountInput.setAttribute('id', 'PaymentCrAmount')
                        hcdeCrAmountInput.setAttribute('maxlength', '10')
                        hcdeCrAmountInput.setAttribute('autocomplete', 'off')
                        hcdeCrAmountInput.setAttribute('placeholder', '')
                        hcdeCrAmountInput.setAttribute('oninput',`onCrAmount('${bal}')`);
                        hcdeCrAmountInput.setAttribute('onchange',`setMinValCr('${bal}')`);
                        hcdeCrAmountInput.value = '$' + bal;

                        let hcdeCrAmountLabel = document.createElement("label");
                        hcdeCrAmountLabel.setAttribute('class', 'hcdeComponentLabel d-inline-block text-start text-truncate form-label col-12')
                        hcdeCrAmountLabel.setAttribute('id', 'PaymentAmountLbl')
                        hcdeCrAmountLabel.innerText = "Payment Amount (Minimum $5.00)";
                        hcdeCrAmount.appendChild(hcdeCrAmountLab)
                        hcdeCrAmount.appendChild(hcdeCrAmountInput)
                        hcdeCrAmount.appendChild(hcdeCrAmountLabel)
                        let hcdeCrBank = document.querySelector('#hcdeZipCodeContainer')
                        hcdeCrBank.after(hcdeCrAmount)

                        let hcdeCrdDateAmount = document.createElement("div");
                        hcdeCrdDateAmount.setAttribute('class', 'hcdeComponentInputContainer form-floating form-group col-12')
                        hcdeCrdDateAmount.setAttribute('id', 'hcdeCrDateAmt');

                        let hcdeCrDateAmountLab = document.createElement("label");
                        hcdeCrDateAmountLab.setAttribute('for', 'PaymentCrDateTime')
                        hcdeCrDateAmountLab.setAttribute('class', 'hcdeComponentLabel')

                        let hcdeCrDateTimeInput = document.createElement("input");
                        hcdeCrDateTimeInput.setAttribute('type', 'text')
                        hcdeCrDateTimeInput.setAttribute('name', 'paymentCrDate')
                        hcdeCrDateTimeInput.setAttribute('class', 'hcdeComponentTextBox form-control')
                        hcdeCrDateTimeInput.setAttribute('id', 'PaymentCrDateTime')
                        hcdeCrDateTimeInput.setAttribute('maxlength', '10')
                        hcdeCrDateTimeInput.setAttribute('autocomplete', 'off')
                        hcdeCrDateTimeInput.setAttribute('placeholder', '')
                        hcdeCrDateTimeInput.setAttribute('disabled', 'true')

                        let hcdeCrDateTimeLabel = document.createElement("label");
                        hcdeCrDateTimeLabel.setAttribute('class', 'hcdeComponentLabel d-inline-block text-start text-truncate form-label col-12')
                        hcdeCrDateTimeLabel.setAttribute('id', 'PaymentCrAmountLbl')
                        hcdeCrDateTimeLabel.innerText = formattedDateValue

                        hcdeCrdDateAmount.appendChild(hcdeCrDateAmountLab)
                        hcdeCrdDateAmount.appendChild(hcdeCrDateTimeInput)
                        hcdeCrdDateAmount.appendChild(hcdeCrDateTimeLabel)
                        let hcdeCrDt = document.querySelector('#hcdeCrAmount')
                        hcdeCrDt.after(hcdeCrdDateAmount)

                        let hcdeButtonCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentButtonContainer');
                        hcdeButtonCon.setAttribute('id', 'hcdeSaveButtonContainer');

                        let hcdeSaveButton = document.getElementById('hcdeComponentSubmitButton');
                        hcdeSaveButton.setAttribute('value', 'Submit');

                        // eslint-disable-next-line no-undef
                        checkCardInfo();
                    });
                    
                }
            });
        },
        savedCardOption(maskedAcctNum, expDate, zipCode, cardType, instrumentId) {
            this.instrumentId = instrumentId;
            this.savedOptionTitle = false;
            this.savedWalletFooter = false;
            this.savedCard = true;
            this.savedBank = false;
            this.cardOptionTitle = true;
            this.cardImg = true;
            this.hcdeReady = true;
            this.hcdeReadyWallet = false;
            const cardscripts = [
                this.hcdeTokenComponent				
            ];
            let formattedDateValue = this.formattedDate
            let bal = this.paymentAmount;
            cardscripts.forEach(script=>{
                let scriptFound = document.head.querySelector(`[src="${ script }"`) ? true : false;
                if (scriptFound) {
                    this.removeJSfile(script);
                }
                let tag = document.head.querySelector(`[src="${ script }"`);
                if (!tag) {
                    tag = document.createElement("script");
                    tag.setAttribute("src", script);
                    tag.setAttribute("type", 'text/javascript');
                    document.head.appendChild(tag);
                    tag.addEventListener('load', function () {
                        // eslint-disable-next-line no-undef
                        this.hcdeTagString = new HCDETokenServiceComponent();                  
                        const digitsOnlyRegExp = /^[0-9]*$/;

                        let hcdeCardNumCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(1)');
                        hcdeCardNumCon.setAttribute('id', 'hcdeCardNumberContainer');
                        hcdeCardNumCon.setAttribute('class', 'hcdeComponentInputContainer form-floating form-group col-12');

                        let hcdeCardNumLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(1) > label');
                        hcdeCardNumLabel.innerText = "";

                        let hcdeCardNumInput = document.getElementById('hcdeCardNumber');
                        hcdeCardNumInput.setAttribute('class', 'hcdeComponentTextBox form-control');
                        hcdeCardNumInput.setAttribute('placeholder', ' ');
                        hcdeCardNumInput.setAttribute('maxlength', '16');
                        hcdeCardNumInput.value = maskedAcctNum;
                        hcdeCardNumInput.disabled = true;

                        let newCardNumLabel = document.createElement('label');
                        newCardNumLabel.setAttribute('class', 'hcdeComponentLabel d-inline-block text-start text-truncate form-label col-12');
                        newCardNumLabel.setAttribute('id', 'hcdeCardNumberLabel');
                        newCardNumLabel.innerText = "Card Number";
                        hcdeCardNumCon.appendChild(newCardNumLabel);

                        let hcdeExpMonthCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(2)');
                        hcdeExpMonthCon.setAttribute('id', 'hcdeExpMonthContainer');
                        hcdeExpMonthCon.setAttribute('class', 'hcdeComponentInputContainer col-6 pe-1');

                        let hcdeExpMonthLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(2) > label');
                        hcdeExpMonthLabel.innerText = "";

                        let hcdeExpMonthInput = document.getElementById('hcdeCardExpiryMonth');
                        hcdeExpMonthInput.disabled = true;
                        let expMonth = expDate.slice(0,2);
                        hcdeExpMonthInput.value = expMonth;

                        let hcdeExpYearCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(3)');
                        hcdeExpYearCon.setAttribute('id', 'hcdeExpYearContainer');
                        hcdeExpYearCon.setAttribute('class', 'hcdeComponentInputContainer col-6 ps-1');

                        let hcdeExpYearLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(3) > label');
                        hcdeExpYearLabel.innerText = "";

                        let hcdeExpYearInput = document.getElementById('hcdeCardExpiryYear');
                        hcdeExpYearInput.disabled = true;
                        let expYear = expDate.slice(-4);
                        hcdeExpYearInput.value = expYear;

                        let hcdeZipCodeCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(4)');
                        hcdeZipCodeCon.setAttribute('id', 'hcdeZipCodeContainer');
                        hcdeZipCodeCon.setAttribute('class', 'hcdeComponentInputContainer form-floating form-group col-6 pe-1');

                        let hcdeZipCodeLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(4) > label');
                        hcdeZipCodeLabel.innerText = "";

                        let hcdeZipCodeInput = document.getElementById('hcdeZipCode');
                        hcdeZipCodeInput.setAttribute('class', 'hcdeComponentTextBox form-control');
                        hcdeZipCodeInput.value = zipCode;
                        hcdeZipCodeInput.disabled = true;

                        let newZipCodeLabel = document.createElement('label');
                        newZipCodeLabel.setAttribute('class', 'hcdeComponentLabel d-inline-block text-start text-truncate form-label col-12');
                        newZipCodeLabel.setAttribute('id', 'hcdeZipCodeLabel');
                        newZipCodeLabel.innerText = "Zip Code";
                        hcdeZipCodeCon.appendChild(newZipCodeLabel);

                        let hcdeCrAmount = document.createElement("div");
                        hcdeCrAmount.setAttribute('class', 'hcdeComponentInputContainer form-floating form-group col-12')
                        hcdeCrAmount.setAttribute('id', 'hcdeCrAmount');

                        let hcdeCrAmountLab = document.createElement("label");
                        hcdeCrAmountLab.setAttribute('for', 'PaymentCrAmount')
                        hcdeCrAmountLab.setAttribute('class', 'hcdeComponentLabel')

                        let hcdeCrAmountInput = document.createElement("input");
                        hcdeCrAmountInput.setAttribute('type', 'text')
                        hcdeCrAmountInput.setAttribute('name', 'paymentCrAmt')
                        hcdeCrAmountInput.setAttribute('class', 'hcdeComponentTextBox form-control')
                        hcdeCrAmountInput.setAttribute('id', 'PaymentCrAmount')
                        hcdeCrAmountInput.setAttribute('maxlength', '10')
                        hcdeCrAmountInput.setAttribute('autocomplete', 'off')
                        hcdeCrAmountInput.setAttribute('placeholder', '')
                        hcdeCrAmountInput.setAttribute('oninput',`onCrAmount('${bal}')`);
                        hcdeCrAmountInput.setAttribute('onchange',`setMinValCr('${bal}')`);
                        hcdeCrAmountInput.value = '$' + bal;

                        let hcdeCrAmountLabel = document.createElement("label");
                        hcdeCrAmountLabel.setAttribute('class', 'hcdeComponentLabel d-inline-block text-start text-truncate form-label col-12')
                        hcdeCrAmountLabel.setAttribute('id', 'PaymentAmountLbl')
                        hcdeCrAmountLabel.innerText = "Payment Amount (Minimum $5.00)";
                        hcdeCrAmount.appendChild(hcdeCrAmountLab)
                        hcdeCrAmount.appendChild(hcdeCrAmountInput)
                        hcdeCrAmount.appendChild(hcdeCrAmountLabel)
                        let hcdeCrBank = document.querySelector('#hcdeZipCodeContainer')
                        hcdeCrBank.after(hcdeCrAmount)

                        let hcdeCrdDateAmount = document.createElement("div");
                        hcdeCrdDateAmount.setAttribute('class', 'hcdeComponentInputContainer form-floating form-group col-12')
                        hcdeCrdDateAmount.setAttribute('id', 'hcdeCrDateAmt');

                        let hcdeCrDateAmountLab = document.createElement("label");
                        hcdeCrDateAmountLab.setAttribute('for', 'PaymentCrDateTime')
                        hcdeCrDateAmountLab.setAttribute('class', 'hcdeComponentLabel')

                        let hcdeCrDateTimeInput = document.createElement("input");
                        hcdeCrDateTimeInput.setAttribute('type', 'text')
                        hcdeCrDateTimeInput.setAttribute('name', 'paymentCrDate')
                        hcdeCrDateTimeInput.setAttribute('class', 'hcdeComponentTextBox form-control')
                        hcdeCrDateTimeInput.setAttribute('id', 'PaymentCrDateTime')
                        hcdeCrDateTimeInput.setAttribute('maxlength', '10')
                        hcdeCrDateTimeInput.setAttribute('autocomplete', 'off')
                        hcdeCrDateTimeInput.setAttribute('placeholder', '')
                        hcdeCrDateTimeInput.setAttribute('disabled', 'true')

                        let hcdeCrDateTimeLabel = document.createElement("label");
                        hcdeCrDateTimeLabel.setAttribute('class', 'hcdeComponentLabel d-inline-block text-start text-truncate form-label col-12')
                        hcdeCrDateTimeLabel.setAttribute('id', 'PaymentCrAmountLbl')
                        hcdeCrDateTimeLabel.innerText = formattedDateValue

                        hcdeCrdDateAmount.appendChild(hcdeCrDateAmountLab)
                        hcdeCrdDateAmount.appendChild(hcdeCrDateTimeInput)
                        hcdeCrdDateAmount.appendChild(hcdeCrDateTimeLabel)
                        let hcdeCrDt = document.querySelector('#hcdeCrAmount')
                        hcdeCrDt.after(hcdeCrdDateAmount)

                        let hcdeButtonCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentButtonContainer');

                        if(hcdeButtonCon){
                            hcdeButtonCon.remove();
                        }
                    });
                    
                }
            });
            setTimeout(() => (
                this.savedWalletFooter = true
            ), 250); 
        },
        bankOption() {
            this.paymentModal.show();
            this.bankOptionTitle = true;
            this.cardOptionTitle = false;
            this.savedOptionTitle = false;
            this.savedWalletFooter = false;
            this.cardImg = false;
            this.hcdeReady = false;
            this.hcdeReadyWallet = true;
            const bankscripts = [
                this.hcdeWalletComponent				
            ];
            let formattedDateValue = this.formattedDate
            let bal = this.paymentAmount;
            bankscripts.forEach(script => {
                let scriptFound = document.head.querySelector(`[src="${ script }"`) ? true : false;
                if (scriptFound) {
                    this.removeJSfile(script);
                }
                let tag = document.head.querySelector(`[src="${ script }"`);
                if (!tag) {
                    tag = document.createElement("script");
                    tag.setAttribute("src", script);
                    tag.setAttribute("type", 'text/javascript');
                    document.head.appendChild(tag); 
                    tag.addEventListener('load', function () {
                        // eslint-disable-next-line no-undef
                        this.hcdeTagString = new HCDEWalletServiceComponent();
                        const digitsOnlyRegExp = /^[0-9]*$/;
                        let hcdeBankDetailCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(1)');
                        hcdeBankDetailCon.setAttribute('id', 'hcdeBankDetailContainer');
                        hcdeBankDetailCon.setAttribute('class', 'hcdeComponentInputContainer form-floating form-group col-12');

                        let hcdeRouteNumLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(1) > label');
                        hcdeRouteNumLabel.innerText = "";

                        let hcdeRouteNumInput = document.getElementById('bankRoutingNumber');
                        hcdeRouteNumInput.setAttribute('class', 'hcdeComponentTextBox form-control');
                        hcdeRouteNumInput.setAttribute('placeholder', ' ');
                        hcdeRouteNumInput.setAttribute('maxlength', '9');
                        hcdeRouteNumInput.setAttribute('oninput', 'onInputRouteNum()');
                        hcdeRouteNumInput.addEventListener("keypress", e => {
                            if (!digitsOnlyRegExp.test(e.key)) {
                                e.preventDefault();
                            }
                        });

                        let newRoutingNumberLabel = document.createElement('label');
                        newRoutingNumberLabel.setAttribute('class', 'hcdeComponentLabel d-inline-block text-start text-truncate form-label col-12');
                        newRoutingNumberLabel.setAttribute('id', 'hcdeRouteNumberLabel');
                        newRoutingNumberLabel.innerText = "Routing Number";
                        hcdeBankDetailCon.appendChild(newRoutingNumberLabel);

                        let hcdeAcctNumCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(2)');
                        hcdeAcctNumCon.setAttribute('id', 'hcdeAccountNumberContainer');
                        hcdeAcctNumCon.setAttribute('class', 'hcdeComponentInputContainer form-floating form-group col-12');

                        let hcdeAcctNumLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(2) > label');
                        hcdeAcctNumLabel.innerText = "";

                        let hcdeAcctNumInput = document.getElementById('bankAccountNumber');
                        hcdeAcctNumInput.setAttribute('class', 'hcdeComponentTextBox form-control');
                        hcdeAcctNumInput.setAttribute('placeholder', '');
                        hcdeAcctNumInput.setAttribute('maxlength', '17');
                        hcdeAcctNumInput.setAttribute('oninput', 'onInputAcctNum()');
                        hcdeAcctNumInput.addEventListener("keypress", e => {
                            if (!digitsOnlyRegExp.test(e.key)) {
                                e.preventDefault();
                            }
                        });

                        let newAcctNumberLabel = document.createElement('label');
                        newAcctNumberLabel.setAttribute('class', 'hcdeComponentLabel d-inline-block text-start text-truncate form-label col-12');
                        newAcctNumberLabel.setAttribute('id', 'hcdeAccountNumberLabel');
                        newAcctNumberLabel.innerText = "Account Number";
                        hcdeAcctNumCon.appendChild(newAcctNumberLabel);

                        let hcdeAcctTypeCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(3)');
                        hcdeAcctTypeCon.setAttribute('id', 'hcdeAccountTypeContainer');
                        hcdeAcctTypeCon.setAttribute('class', 'hcdeComponentInputContainer col-12');

                        let hcdeAcctTypeLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(3) > label');
                        hcdeAcctTypeLabel.innerHTML = '';

                        let hcdeAcctTypeInput = document.getElementById('bankAccountType');
                        hcdeAcctTypeInput.options[0].innerText = 'Bank account type';
                        hcdeAcctTypeInput.setAttribute('onchange', 'onInputAcctType()');

                        let hcdeAmount = document.createElement("div");
                        hcdeAmount.setAttribute('class', 'hcdeComponentInputContainer form-floating form-group col-12')
                        hcdeAmount.setAttribute('id', 'hcdeAmount');

                        let hcdeAmountLab = document.createElement("label");
                        hcdeAmountLab.setAttribute('for', 'PaymentAmount')
                        hcdeAmountLab.setAttribute('class', 'hcdeComponentLabel')

                        let hcdeAmountInput = document.createElement("input");
                        hcdeAmountInput.setAttribute('type', 'text')
                        hcdeAmountInput.setAttribute('name', 'paymentAmt')
                        hcdeAmountInput.setAttribute('class', 'hcdeComponentTextBox form-control')
                        hcdeAmountInput.setAttribute('id', 'PaymentAmount')
                        hcdeAmountInput.setAttribute('maxlength', '10')
                        hcdeAmountInput.setAttribute('autocomplete', 'off')
                        hcdeAmountInput.setAttribute('placeholder', '')
                        hcdeAmountInput.setAttribute('oninput',`onAmount('${bal}')`);
                        hcdeAmountInput.setAttribute('onchange',`setMinVal('${bal}')`);
                        hcdeAmountInput.value = '$' + bal;

                        let hcdeAmountLabel = document.createElement("label");
                        hcdeAmountLabel.setAttribute('class', 'hcdeComponentLabel d-inline-block text-start text-truncate form-label col-12')
                        hcdeAmountLabel.setAttribute('id', 'PaymentAmountLbl')
                        hcdeAmountLabel.innerText = "Payment Amount (Minimum $5.00)";
                        hcdeAmount.appendChild(hcdeAmountLab)
                        hcdeAmount.appendChild(hcdeAmountInput)
                        hcdeAmount.appendChild(hcdeAmountLabel)
                        let hcdeCardBank = document.querySelector('#hcdeAccountTypeContainer')
                        hcdeCardBank.after(hcdeAmount)

                        let hcdeDateAmount = document.createElement("div");
                        hcdeDateAmount.setAttribute('class', 'hcdeComponentInputContainer form-floating form-group col-12')
                        hcdeDateAmount.setAttribute('id', 'hcdeDateAmt');

                        let hcdeDateAmountLab = document.createElement("label");
                        hcdeDateAmountLab.setAttribute('for', 'PaymentDateTime')
                        hcdeDateAmountLab.setAttribute('class', 'hcdeComponentLabel')

                        let hcdeDateTimeInput = document.createElement("input");
                        hcdeDateTimeInput.setAttribute('type', 'text')
                        hcdeDateTimeInput.setAttribute('name', 'paymentDate')
                        hcdeDateTimeInput.setAttribute('class', 'hcdeComponentTextBox form-control')
                        hcdeDateTimeInput.setAttribute('id', 'PaymentDateTime')
                        hcdeDateTimeInput.setAttribute('maxlength', '10')
                        hcdeDateTimeInput.setAttribute('autocomplete', 'off')
                        hcdeDateTimeInput.setAttribute('placeholder', '')
                        hcdeDateTimeInput.setAttribute('disabled', 'true')

                        let hcdeDateTimeLabel = document.createElement("label");
                        hcdeDateTimeLabel.setAttribute('class', 'hcdeComponentLabel d-inline-block text-start text-truncate form-label col-12')
                        hcdeDateTimeLabel.setAttribute('id', 'PaymentAmountLbl')
                        hcdeDateTimeLabel.innerText = formattedDateValue
                        hcdeDateAmount.appendChild(hcdeDateAmountLab)
                        hcdeDateAmount.appendChild(hcdeDateTimeInput)
                        hcdeDateAmount.appendChild(hcdeDateTimeLabel)
                        let hcdeDt = document.querySelector('#hcdeAmount')
                        hcdeDt.after(hcdeDateAmount)

                        let hcdeBankButtonCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentButtonContainer');
                        hcdeBankButtonCon.setAttribute('id', 'hcdeSaveBankButtonContainer');

                        let hcdeSaveBankButton = document.getElementById('hcdeComponentSubmitButton');
                        hcdeSaveBankButton.setAttribute('value', 'Submit');

                        // eslint-disable-next-line no-undef
                        checkBankInfo();
                        console.log("status " + this.statusVar)
                    });
                }  
            });
        },
        savedBankOption(bankRoutingNumber, bankAcctNumber, bankAcctType, instrumentId) {
            this.instrumentId = instrumentId;
            this.savedOptionTitle = false;
            this.savedWalletFooter = false;
            this.savedCard = false;
            this.savedBank = true;
            this.bankOptionTitle = true;
            this.hcdeReady = false;
            this.hcdeReadyWallet = true;
            const bankscripts = [
                this.hcdeWalletComponent				
            ];
            let formattedDateValue = this.formattedDate
            let bal = this.paymentAmount;
            bankscripts.forEach(script => {
                let scriptFound = document.head.querySelector(`[src="${ script }"`) ? true : false;
                if (scriptFound) {
                    this.removeJSfile(script);
                }
                let tag = document.head.querySelector(`[src="${ script }"`);
                if (!tag) {
                    tag = document.createElement("script");
                    tag.setAttribute("src", script);
                    tag.setAttribute("type", 'text/javascript');
                    document.head.appendChild(tag); 
                    tag.addEventListener('load', function () {
                        // eslint-disable-next-line no-undef
                        this.hcdeTagString = new HCDEWalletServiceComponent();
                        const digitsOnlyRegExp = /^[0-9]*$/;
                        let hcdeBankDetailCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(1)');
                        hcdeBankDetailCon.setAttribute('id', 'hcdeBankDetailContainer');
                        hcdeBankDetailCon.setAttribute('class', 'hcdeComponentInputContainer form-floating form-group col-12');

                        let hcdeRouteNumLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(1) > label');
                        hcdeRouteNumLabel.innerText = "";

                        let hcdeRouteNumInput = document.getElementById('bankRoutingNumber');
                        hcdeRouteNumInput.setAttribute('class', 'hcdeComponentTextBox form-control');
                        hcdeRouteNumInput.value = bankRoutingNumber;
                        hcdeRouteNumInput.disabled = true;

                        let newRoutingNumberLabel = document.createElement('label');
                        newRoutingNumberLabel.setAttribute('class', 'hcdeComponentLabel d-inline-block text-start text-truncate form-label col-12');
                        newRoutingNumberLabel.setAttribute('id', 'hcdeRouteNumberLabel');
                        newRoutingNumberLabel.innerText = "Routing Number";
                        hcdeBankDetailCon.appendChild(newRoutingNumberLabel);

                        let hcdeAcctNumCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(2)');
                        hcdeAcctNumCon.setAttribute('id', 'hcdeAccountNumberContainer');
                        hcdeAcctNumCon.setAttribute('class', 'hcdeComponentInputContainer form-floating form-group col-12');

                        let hcdeAcctNumLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(2) > label');
                        hcdeAcctNumLabel.innerText = "";

                        let hcdeAcctNumInput = document.getElementById('bankAccountNumber');
                        hcdeAcctNumInput.setAttribute('class', 'hcdeComponentTextBox form-control');
                        hcdeAcctNumInput.value = bankAcctNumber;
                        hcdeAcctNumInput.disabled = true;


                        let newAcctNumberLabel = document.createElement('label');
                        newAcctNumberLabel.setAttribute('class', 'hcdeComponentLabel d-inline-block text-start text-truncate form-label col-12');
                        newAcctNumberLabel.setAttribute('id', 'hcdeAccountNumberLabel');
                        newAcctNumberLabel.innerText = "Account Number";
                        hcdeAcctNumCon.appendChild(newAcctNumberLabel);

                        let hcdeAcctTypeCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(3)');
                        hcdeAcctTypeCon.setAttribute('id', 'hcdeAccountTypeContainer');
                        hcdeAcctTypeCon.setAttribute('class', 'hcdeComponentInputContainer col-12');

                        let hcdeAcctTypeLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(3) > label');
                        hcdeAcctTypeLabel.innerHTML = '';

                        let hcdeAcctTypeInput = document.getElementById('bankAccountType');
                        hcdeAcctTypeInput.value = bankAcctType;
                        hcdeAcctTypeInput.disabled = true;

                        let hcdeAmount = document.createElement("div");
                        hcdeAmount.setAttribute('class', 'hcdeComponentInputContainer form-floating form-group col-12')
                        hcdeAmount.setAttribute('id', 'hcdeAmount');

                        let hcdeAmountLab = document.createElement("label");
                        hcdeAmountLab.setAttribute('for', 'PaymentAmount')
                        hcdeAmountLab.setAttribute('class', 'hcdeComponentLabel')

                        let hcdeAmountInput = document.createElement("input");
                        hcdeAmountInput.setAttribute('type', 'text')
                        hcdeAmountInput.setAttribute('name', 'paymentAmt')
                        hcdeAmountInput.setAttribute('class', 'hcdeComponentTextBox form-control')
                        hcdeAmountInput.setAttribute('id', 'PaymentAmount')
                        hcdeAmountInput.setAttribute('maxlength', '10')
                        hcdeAmountInput.setAttribute('autocomplete', 'off')
                        hcdeAmountInput.setAttribute('placeholder', '')
                        hcdeAmountInput.setAttribute('oninput',`onAmount('${bal}')`);
                        hcdeAmountInput.setAttribute('onchange',`setMinVal('${bal}')`);
                        hcdeAmountInput.value = '$' + bal;

                        let hcdeAmountLabel = document.createElement("label");
                        hcdeAmountLabel.setAttribute('class', 'hcdeComponentLabel d-inline-block text-start text-truncate form-label col-12')
                        hcdeAmountLabel.setAttribute('id', 'PaymentAmountLbl')
                        hcdeAmountLabel.innerText = "Payment Amount (Minimum $5.00)";
                        hcdeAmount.appendChild(hcdeAmountLab)
                        hcdeAmount.appendChild(hcdeAmountInput)
                        hcdeAmount.appendChild(hcdeAmountLabel)
                        let hcdeCardBank = document.querySelector('#hcdeAccountTypeContainer')
                        hcdeCardBank.after(hcdeAmount)

                        let hcdeDateAmount = document.createElement("div");
                        hcdeDateAmount.setAttribute('class', 'hcdeComponentInputContainer form-floating form-group col-12')
                        hcdeDateAmount.setAttribute('id', 'hcdeDateAmt');

                        let hcdeDateAmountLab = document.createElement("label");
                        hcdeDateAmountLab.setAttribute('for', 'PaymentDateTime')
                        hcdeDateAmountLab.setAttribute('class', 'hcdeComponentLabel')

                        let hcdeDateTimeInput = document.createElement("input");
                        hcdeDateTimeInput.setAttribute('type', 'text')
                        hcdeDateTimeInput.setAttribute('name', 'paymentDate')
                        hcdeDateTimeInput.setAttribute('class', 'hcdeComponentTextBox form-control')
                        hcdeDateTimeInput.setAttribute('id', 'PaymentDateTime')
                        hcdeDateTimeInput.setAttribute('maxlength', '10')
                        hcdeDateTimeInput.setAttribute('autocomplete', 'off')
                        hcdeDateTimeInput.setAttribute('placeholder', '')
                        hcdeDateTimeInput.setAttribute('disabled', 'true')

                        let hcdeDateTimeLabel = document.createElement("label");
                        hcdeDateTimeLabel.setAttribute('class', 'hcdeComponentLabel d-inline-block text-start text-truncate form-label col-12')
                        hcdeDateTimeLabel.setAttribute('id', 'PaymentAmountLbl')
                        hcdeDateTimeLabel.innerText = formattedDateValue
                        hcdeDateAmount.appendChild(hcdeDateAmountLab)
                        hcdeDateAmount.appendChild(hcdeDateTimeInput)
                        hcdeDateAmount.appendChild(hcdeDateTimeLabel)
                        let hcdeDt = document.querySelector('#hcdeAmount')
                        hcdeDt.after(hcdeDateAmount)

                        let hcdeBankButtonCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentButtonContainer');

                        if(hcdeBankButtonCon){
                            hcdeBankButtonCon.remove();
                        }
                    });
                    
                }
            });
            setTimeout(() => (
                this.savedWalletFooter = true
            ), 250); 
        },
        async createHcdeTagListener() {
            const scripts = [
                this.WalletJSEncrypt,
                this.hcdeTokenServiceApi,
                this.WalletJSEncrypt,
                this.hcdeWalletServiceApi
            ];
            scripts.forEach(script => {
                let tag = document.head.querySelector(`[src="${ script }"`);
                if (!tag) {
                    tag = document.createElement("script");
                    tag.setAttribute("src", script);
                    tag.setAttribute("type", 'text/javascript');
                    document.head.appendChild(tag); 
                }
            });
        },
        ModalClose() {
            this.paymentModal.hide();
            this.hcdeReady = false;
            this.hcdeReadyWallet = false;
        },
        ClosePopup() {
          this.popupModal.hide();
        },
        removeJSfile(oldfilename) {
            let targetelement = 'script';
            let targetattr = 'src';
            let allsuspects = document.getElementsByTagName(targetelement);
            for (let i = allsuspects.length; i >= 0; i--) {
                //search backwards within nodelist for matching elements to remove
                if (allsuspects[i] && allsuspects[i].getAttribute(targetattr) != null && allsuspects[i].getAttribute(targetattr).indexOf(oldfilename) != -1) {
                    allsuspects[i].parentNode.removeChild(allsuspects[i]);
                }
            }
        },
        async getPostBillIframeDetail() {
            let attributesData = new Map();
            let localData = new Map();
            const params = {
                accountNumber: this.accountNumber,
                walletRefNo: this.walletRefNo,
                emailAddress: this.emailAddress
            }
            try {
                const res = await axios.post(constants.PostLoginBillPaymentEpwfUrl, params);
                this.iframeSession = res.data.epwfSession ? res.data.epwfSession : null;
                this.postBillIframeUrl = res.data.epwfSession.sessionURL;
                this.sessionId = res.data.epwfSession.sessionId;
                this.paymentAmount = res.data.currentAmountDue ? Number(res.data.currentAmountDue).toFixed(2) : null;
                this.paymentDate = res.data.paymentDueDate ? this.getHumanReadbledt(res.data.paymentDueDate) : null;
                this.isAutopayEnrolled = res.data.autoPay;
                this.firstName = res.data.firstName;
                this.lastName = res.data.lastName;
                this.streetAddress1 = res.data.billingAddress.addressLine1;
                this.streetAddress2 = res.data.billingAddress.addressLine2;
                this.city = res.data.billingAddress.city;
                this.stateProvince = res.data.billingAddress.stateCode;
                this.postalCode = res.data.billingAddress.zip;
                attributesData.set('currentAmountDue', this.paymentAmount)
                attributesData.set('paymentDate', res.data.paymentDueDate)
                this.isAchAllowed = res.data.achAllowed;
                this.isCardAllowed = res.data.cardAllowed;
                attributesData.set("convenienceCardFee", JSON.stringify(res.data.convenienceFeeAmtCard))
                attributesData.set("convenienceBankFee", JSON.stringify(res.data.convenienceFeeAmtAch))
                attributesData.set('isAutopayEnrolled', this.isAutopayEnrolled)
                this.accountNumber= this.$store.getters[GET_DATA]('accountNumber');
                attributesData.set('accountNumber', this.accountNumber)
                this.$store.dispatch(SET_DATA, {attributes:attributesData})
                this.showLoader = false
                localData.set('showLoading', false);
                this.$store.dispatch(local_setdata, {attributes:localData});
            } catch (error) {
                console.log(error)
                this.showLoader = false
                localData.set('showLoading', false);
                this.$store.dispatch(local_setdata, {attributes:localData});
            }
            localData.set('showLoading', false);
            this.$store.dispatch(local_setdata, {attributes:localData});
        },
        getHumanReadbledt(timeStamp) {
            const monthArr = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
            ];
            const date = new Date(timeStamp);
            const year = date.getFullYear();
            const month = monthArr[date.getMonth()];
            const newDate = date.getDate();
            const readableDate = month + " " + newDate + ", " + year;
            return readableDate;
        },
        getPostBillResolution() {
            if( this.postBillIframeChange == 1 ){
                this.postBillIframeHeight = 1425;
            }else{
                this.postBillIframeChange++;
            }
        }
    }
}
</script>

<style>
.large {
  text-align: left;
  --bs-modal-width: 760px;
}
.content-size {
  padding: 1rem;
}
.align-center {
  padding: 1rem;
  display: flex;
  justify-content: center;
}
.flex-component {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  font-size: 16px;
  text-align: left;
  gap: .5rem;
}
.flex-component-button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.content-spacing {
  padding: 1.75rem;
}
.alert-content {
  padding-top: 0.75rem;
}
.flex-entry {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 16px;
  text-align: end;
  margin-left: 1rem;
  gap: 0.75rem;
}
.flex-entry-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 16px;
  text-align: end;
  margin-left: 0rem;
  gap: 0.75rem;
}
.flex-label {
  width: 10rem;
}
.flex-span {
  display: flex;
  flex-direction: row;
}
.Submit-Button{
  background-color: #0047bb;
  border-radius: 4px;
  font-weight: 400;
  height: 36px;
  width: 90px;
  padding: 0;
  text-transform: none;
  margin-left: 5px;
  display: inline-block;
  text-decoration: none;
  color: #fff;
  border: none;
}
.cancel-button {
  border: none;
  color: #0047bb;
  text-decoration: underline;
  background: none;
  padding: 0rem;
}
#myModal1 .modal-body {
  padding-top: 0 !important;
}
.img-card {
    position: relative;
}
.MASTERCARD .img-card:before {
    background-image: url(../../images/Card.png);
    background-repeat: no-repeat;
    position: absolute;
    left: 5px;
    content: "";
    width: 50px;
    height: 50px;
    background-position: center;
    background-size: 100%;
    top: 0px;
}
.VISA .img-card:before {
    background-image: url(../../images/visacard.png);
    background-repeat: no-repeat;
    position: absolute;
    left: 5px;
    content: "";
    width: 50px;
    height: 50px;
    background-position: center;
    background-size: 65%;
    top: 0px;
    border-radius: 50%;
    background-color: #E0E8F7;
}
.MASTER .img-card:before {
    background-image: url(../../images/visacard.png);
    background-repeat: no-repeat;
    position: absolute;
    left: 5px;
    content: "";
    width: 50px;
    height: 50px;
    background-position: center;
    background-size: 65%;
    top: 0px;
    border-radius: 50%;
    background-color: #E0E8F7;
}
.DISCOVER .img-card:before {
    background-image: url(../../images/visacard.png);
    background-repeat: no-repeat;
    position: absolute;
    left: 5px;
    content: "";
    width: 50px;
    height: 50px;
    background-position: center;
    background-size: 65%;
    top: 0px;
    border-radius: 50%;
    background-color: #E0E8F7;
}
.img-bank {
    position: relative;
}
.Savings .img-bank:before {
    background-image: url(../../images/institute.png);
    background-repeat: no-repeat;
    position: absolute;
    left: 5px;
    content: "";
    width: 50px;
    height: 50px;
    background-position: center;
    background-size: 100%;
    top: 0px;
}
.Checking .img-bank:before {
    background-image: url(../../images/institute.png);
    background-repeat: no-repeat;
    position: absolute;
    left: 5px;
    content: "";
    width: 50px;
    height: 50px;
    background-position: center;
    background-size: 100%;
    top: 0px;
}
.img-content-container p {
    text-transform: lowercase;
    padding: 10px 35px 0 60px;
    color:#000000;
}
.img-content-container p > span {
    display: inline-block;
}
.img-content-container p > span:first-letter {
    text-transform: uppercase;
}
.img-card {
    position: relative;
}
.wallet-box {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #00000029;
    border: 1px solid #EEEEEE33;
    border-radius: 8px;
    opacity: 1;
    padding: 16px;
    text-align: left;
    margin-bottom: 12px;
    min-height: 70px;
    cursor: pointer;
    height: 84px;
}
#card-img-section{
    text-align: left;
    padding-left: 6px;
}
.visa-img, .mastercard-img, .discover-img {
    height: 30px;
    width: 32px;
    margin-left: 10px;
}
#brimButtonBlock{
    margin-bottom: 15px;
}
.pb-payment-cta {
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #0047BB;
  color: #0047BB;
  margin: 10px;
  padding: 6px 12px;
}
iframe{
    overflow: hidden;
}
#postBillIframe {
    overflow: hidden;
}
.back-arrow {
    margin-right: 10px;
    width: 20px;
}
.text-decor {
    color: #14161a;
    font-weight: 500;
    margin-top: 1rem;
}
.paymentDetailBlock {
    width: 100%;
    height: auto;
    background-color: #ffffff;
    margin-top: 15px;
    box-shadow: 0px 2px 4px #00000033;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 10px;
}
.bill-iframe {
    margin: 3rem 0.5rem 3rem 0;
    padding: 0px;
    overflow: hidden;
}
.icon-circle {
    height: 40px;
    display: inline-block;
    margin-left: 0.5rem;
    border-radius: 60px;
    box-shadow: 0 0 2px #888;
    padding: 0.5em 0.6em;
    color: #0047bb;
    background-color: #e0e8f7;
}
.icon-text-margin {
    margin-left: 0px;
}

.bill-iframe::-webkit-scrollbar {
  display: none;
}
div::-webkit-scrollbar {
  display: none;
}
h1.text-decor {
    font-size: 36px;
}
@media screen and (min-width: 375px) and (max-width: 979px) {
    .icon-text-margin {
        margin-left: 20px;
    }
}
@media(max-width:992px) {
  .pb-payment-cta {
    width: 100%;
    margin: 10px 0;
  }
}
@media (min-width: 767px) {
    .wallet-cards {
        width: 48%;
        float: left;
    }
    .wallet-bank {
        width: 48%;
        float: left;
    }
    .wallet-box:nth-child(even) {
        margin-left: 2%;
    }
    .wallet-box:nth-child(odd) {
        margin-right: 2%;
    }
}
@media(max-width:767px) {
  .flex-component-button {
    gap: 3rem;
  }
  .p {
    font-size: 14px;
  }
  .large{
    --bs-modal-width: 600px !important;
  }
  .flex-entry{
    margin-left: -2rem;
    gap: 0.25rem;
  }
  .align-center {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
  .flex-span {
    word-break: break-all;
  }
}
</style>
