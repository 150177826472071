<template>
  <div class="qbpMessageBox">
        <p class="content-align"><img class="alertcss pointer" src="../../images/icon-important-orange.svg" alt="close icon"></p>
          <div class="align-itms">Your account is locked. Please
            <a href="javascript:startChat({skillName:'Watson_24_7_Embedded_Service',buttonId:'Watson_24_7_Chat_Bot'})">contact us</a> to unlock your account.</div>
</div>
</template>
<script>
import links from "../../js/links";
import { pii } from "../../js/pii";

export default {
  name: "BlockedAccount",
  data() {
    return {
      MyaEntryPointURL: links.MyaEntryPointURL,
    };
  },
  mounted() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "qbp_blocked account",
      accountNumber: pii(this.accountNumber),
      errorName: "blocked account",
      flow: {
        name: "qbp",
        step: "payment flow",
        event: "blocked account",
      },
    });
  },
};
</script>
<style scoped>
.qbpMessageBox{
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.text-color {
  color: #0176d3;
  text-decoration: none !important;
}
.content-margin {
  margin: 2rem 0 2rem 0;
}

.iframe-error-margin {
  margin: 1rem 0 1rem 0;
  display: flex;
}

#qbp-exception-message h3 {
  font-weight: bold;
}

#qbp-exception-message p {
  margin-bottom: 5px;
}

.qbpTextBox {
  position: relative;
}
.align-itms{
  margin-top: 5px;
}


@media (max-width: 767px) {
  #qbp-exception-message h3 {
    min-height: 30px;
  }
  .content-align{
    padding-left: 2rem;
  }
}
</style>