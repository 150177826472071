<template>
  <div class="col-md-12 col-xs-12">
    <div class="text-center px-3" v-if="this.showPaNoti">
      <p class="pa-info"><img class="info-img" alt="info-image" src="../../images/info.svg"/><span class="span-text-pa text-start">You are enrolled in a Payment Plan</span><button type="button" id="pa-notify-close" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" @click="onCrossClick()"></button></p>
    </div>
    <div id="dssp-intro-wrapper">
      <h1 data-test="messageHead">Quick Bill Pay</h1>
      <h2 data-test="accountNumber">Payment for {{this.accountNumber}} </h2>
    </div>
    <div class="container-fluid total-amount-banner">
      <div class="qbp-confirm-details">
        <div v-if="this.remainingAmt <= -200.00 || this.errorMessage == 'QBP_AUTOPAY'" ></div> 
        <div class="row qbp-details" v-else>         
          <div class="col-6 col-sm-5 qbp-left-details">
            <!-- Check for adjusted amount -->
            <div class="total-amt" v-if="totalBalanceOwed">TOTAL BALANCE OWED</div>
            <div class="total-bal" v-if="totalBalanceOwed"><span class="dollar">$</span>{{ twoDecimal(this.totalBalanceOwed) }}</div>
            <div class="due-on" v-if="paymentDueDate">Due on {{ getHumanReadbledt(this.paymentDueDate) }}</div>
            <!-- if multiaccount is true show the link to navigate multiaccount page -->
            <div v-if="this.statusMessage == true">
              <a :href="'/qbp/multiAccount'">Change Account </a>
            </div>
          </div>
          <div v-if="remainingAmt" class="col-6 col-sm-7 qbp-banner-img">
            <img alt="Banner-image" src="../../images/AmtBanner.png" />
          </div>
        </div>
      </div>
    </div>
    <div class="text-start">
      <div class="confirm-iframe-error">
        <!-- display iframe for payment -->
        <div v-if="((this.Iframe != '') && (this.brim == false)) ">
          <div class="confirm-heading">
            <div class="confirm-payment">PAYMENT METHOD</div>
            <div class="confirm-method">Choose your method of payment</div>
            <div class="confirm-account">You can pay using your bank account or credit/debit card.</div>
          </div>
          <!-- show the installment start -->
          <div class="accordion pb-4 text-start" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  View Payment Plan details
                </button>
              </h2>
              <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <div class="row">
                    <div class="col-8 text-start"> 
                      <div class="installment-header"> Total installment plan</div>
                    </div>
                      <div class="col-4 text-end">
                        <div class="installment-primary">${{this.totalBalanceOwed}}</div>
                      </div>
                  </div>   
                  <div class="horizontal-row py-2">
                    <div class="horizontal-border">
                    </div>
                  </div>
                  <div v-for="(installment, index) in installments" :key="installment.installAmount" class="text-center">
                    <div class="row">
                      <div class="col-8 text-start installment-primary">Installment {{ index + 1 }}</div>
                      <div v-if="installment.status=='Open'" class="col-4 text-end installment-primary">${{twoDecimal(installment.installAmount - installment.coveredAmount)}}</div>
                      <div v-else-if="installment.status=='Completed'" class="col-4 text-end installment-primary">${{twoDecimal(installment.installAmount) }}</div>
                      <div v-if="installment.status=='Open'" class="col-12 text-start installment-secondary">Will be charged on {{getHumanReadbledt(installment.installDate)}}.</div>
                      <div v-else-if="installment.status=='Completed'" class="col-12 text-start installment-secondary">Charged on {{getHumanReadbledt(installment.statusDate)}}.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>  
          </div>
          <!-- show the installment end -->
          <div class="iframe-prepaid-border">
            <iframe
              width="100%"
              style="height: 700px; border: none; overflow: hidden;"
              :src="this.Iframe" title="Quick bill payment" class="qbp-iframe-container"
            ></iframe>
          </div>
        </div>
        <div v-else-if="this.brim">
          <div class="confirm-heading">
            <div class="confirm-payment">PAYMENT METHOD</div>
            <div class="confirm-method">Choose your method of payment</div>
            <div class="confirm-account">You can pay using your bank account or credit/debit card.</div>
          </div>
          <!-- show the installment start -->
          <div class="accordion pb-4 text-start" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  View Payment Plan details
                </button>
              </h2>
              <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <div class="row">
                    <div class="col-8 text-start"> 
                      <div class="installment-header"> Total installment plan</div>
                    </div>
                      <div class="col-4 text-end">
                        <div class="installment-primary">${{this.totalBalanceOwed}}</div>
                      </div>
                  </div>   
                  <div class="horizontal-row py-2">
                    <div class="horizontal-border">
                    </div>
                  </div>
                  <div v-for="(installment, index) in installments" :key="installment.installAmount" class="text-center">
                    <div class="row">
                      <div class="col-8 text-start installment-primary">Installment {{ index + 1 }}</div>
                      <div v-if="installment.status=='Open'" class="col-4 text-end installment-primary">${{twoDecimal(installment.installAmount - installment.coveredAmount)}}</div>
                      <div v-else-if="installment.status=='Completed'" class="col-4 text-end installment-primary">${{twoDecimal(installment.installAmount) }}</div>
                      <div v-if="installment.status=='Open'" class="col-12 text-start installment-secondary">Will be charged on {{getHumanReadbledt(installment.installDate)}}.</div>
                      <div v-else-if="installment.status=='Completed'" class="col-12 text-start installment-secondary">Charged on {{getHumanReadbledt(installment.statusDate)}}.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>  
          </div>
          <!-- show the installment end -->
          <div class="iframe-prepaid-border text-center">
            <button v-if="this.isAchAllowed" type="button" class="btn qbp-payment-cta" @click="bankOption">Pay With Bank Account</button>
            <button v-if="this.isCardAllowed" type="button" class="btn qbp-payment-cta" @click="cardOption">Pay With Credit or Debit Card</button>
          </div>
        </div>
        <div v-else>
          <p><Iframe title="no-iframe"/></p>
        </div>
      </div>
    </div>
    <div id="myModal" class="modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header no-border">
            <h5 v-if="this.bankOptionTitle" class="modal-title">Enter bank account info</h5>
            <h5 v-if="this.cardOptionTitle" class="modal-title">Enter card info</h5>
            <button type="button" class="btn" @click="ModalClose()" aria-label="Close"> 
                <img src="../../images/svg/icon-navigation-close_24px.svg" alt="outline-info"/>
            </button>
          </div>
            <div class="modal-body">
              <div class="hcde-comp">
                <hcde-tokenization v-if="this.hcdeReady" :env="this.env" clientId="MYA_PAY" :sessionId="this.sessionId" callbackFunction="showCardResponse" callbackField="myTokenVar" statusFunction="tokenStatus" statusField="statusVar" heartBeatFrequency="60" srcSysTransId="307902021108">Loading...</hcde-tokenization>
                <hcde-wallet v-if="this.hcdeReadyWallet" :env="this.env" clientId="MYA_PAY" :sessionId="this.sessionId" callbackFunction="showBankResponse" callbackRefNumField="walletRefNum"  callbackInstrumentField="walletInstrumentId" statusFunction="walletStatus" statusField="statusVar" heartBeatFrequency="60" srcSysTransId="1234567890" >Loading...</hcde-wallet>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Modal } from "bootstrap/dist/js/bootstrap.bundle";
import Iframe from '../ErrorMessages/IframeError';
import { GET_DATA, SET_DATA } from '../../store/storecontants';
import { pii } from "../../js/pii";
import links from '../../js/links'
import hcdeStatus from "../../js/hcdestatus";
  export default {
    components :{
      Iframe
    },
    data() {
      return {
        installments : [],
        Iframe: null,
        totalBalanceOwed: null,
        accountNumber: null,
        currMonthlyAmt : null,
        adjustedAmountAvailable : null,
        paymentDueDate: null,
        remainingAmt: null,
        showPaNoti: false,
        hcdeReady: false,
        hcdeReadyWallet: false,
        myTokenVar: null,
        walletInstrumentId: null,
        hcdeTagString: null,
        brim: false,
        WalletJSEncrypt: null,
        hcdeWalletServiceApi: null,
        hcdeTokenServiceApi: null,
        hcdeWalletComponent: null,
        hcdeTokenComponent: null,
        paymentModal: null,
        sessionId: null,
        formattedDate: '',
        bankOptionTitle: false,
        cardOptionTitle: false,
        isCardAllowed: false,
        isAchAllowed: false
      };
    },
    methods :{
      // Close method for PA banner 
      onCrossClick () {
        this.showPaNoti = false;
      },
      checkStatus(status) {
        if(status.includes("EXPIRED")){
          this.ModalClose()
          this.$router.push({
              name: "FailureOfPayment",
              params: {showTimeout: 'timeOut'},
          });
        }
      },
      //formate date
      formatDate(dateString){
        const date = dateString.split("-");
        return (date[1]+ '/' + date[2]  + '/' + date[0]);
      },
      // readable formate
      getHumanReadbledt(timeStamp) {
        if (timeStamp == null) {
          return null;
        }
        const monthArr = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        const date = new Date(timeStamp);
        const year = date.getFullYear();
        const month = monthArr[date.getMonth()];
        const newDate = date.getDate();
        const readableDate = month + " " + newDate + ", " + year;
        return readableDate;
      },
      // two decimal
      twoDecimal(twodecimal) {
        let num = parseFloat(twodecimal)
        let with2Decimals = num.toFixed(2)
        return with2Decimals
      },
      removeJSfile(oldfilename) {
        let targetelement = 'script';
        let targetattr = 'src';
        let allsuspects = document.getElementsByTagName(targetelement);
        for (let i = allsuspects.length; i >= 0; i--) {
            //search backwards within nodelist for matching elements to remove
            if (allsuspects[i] && allsuspects[i].getAttribute(targetattr) != null && allsuspects[i].getAttribute(targetattr).indexOf(oldfilename) != -1) {
                allsuspects[i].parentNode.removeChild(allsuspects[i]);
            }
        }
      },
      async createHcdeTagListener() {
        const scripts = [
            this.WalletJSEncrypt,
            this.hcdeTokenServiceApi,
            this.WalletJSEncrypt,
            this.hcdeWalletServiceApi
        ];
        scripts.forEach(script => {
            let tag = document.head.querySelector(`[src="${ script }"`);
            if (!tag) {
                tag = document.createElement("script");
                tag.setAttribute("src", script);
                tag.setAttribute("type", 'text/javascript');
                document.head.appendChild(tag); 
            }
        });
      },
      cardOption() {
        this.paymentModal.show();
        this.bankOptionTitle = false
        this.cardOptionTitle = true
        this.hcdeReady = true;
        this.hcdeReadyWallet = false;  
        const cardscripts = [
            this.hcdeTokenComponent				
        ];
        let formattedDateValue = this.formattedDate
        let bal = this.totalBalanceOwed;
        cardscripts.forEach(script=>{
            let scriptFound = document.head.querySelector(`[src="${ script }"`) ? true : false;
            if (scriptFound) {
                this.removeJSfile(script);
            }
            let tag = document.head.querySelector(`[src="${ script }"`);
            if (!tag) {
                tag = document.createElement("script");
                tag.setAttribute("src", script);
                tag.setAttribute("type", 'text/javascript');
                document.head.appendChild(tag);
                tag.addEventListener('load', function () {
                    // eslint-disable-next-line no-undef
                    this.hcdeTagString = new HCDETokenServiceComponent();                  
                    const digitsOnlyRegExp = /^[0-9]*$/;

                    let hcdeCardNumCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(1)');
                    hcdeCardNumCon.setAttribute('id', 'hcdeCardNumberContainer');
                    hcdeCardNumCon.setAttribute('class', 'hcdeComponentInputContainer form-floating form-group col-12');

                    let hcdeCardNumLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(1) > label');
                    hcdeCardNumLabel.innerText = "";

                    let hcdeCardNumInput = document.getElementById('hcdeCardNumber');
                    hcdeCardNumInput.setAttribute('class', 'hcdeComponentTextBox form-control');
                    hcdeCardNumInput.setAttribute('placeholder', ' ');
                    hcdeCardNumInput.setAttribute('maxlength', '16');
                    hcdeCardNumInput.setAttribute('oninput', 'onInputCardNum()');
                    hcdeCardNumInput.addEventListener("keypress", e => {
                        if (!digitsOnlyRegExp.test(e.key)) {
                            e.preventDefault();
                        }
                    });

                    let newCardNumLabel = document.createElement('label');
                    newCardNumLabel.setAttribute('class', 'hcdeComponentLabel d-inline-block text-start text-truncate form-label col-12');
                    newCardNumLabel.setAttribute('id', 'hcdeCardNumberLabel');
                    newCardNumLabel.innerText = "Card Number";
                    hcdeCardNumCon.appendChild(newCardNumLabel);
                    
                    let hcdeExpMonthCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(2)');
                    hcdeExpMonthCon.setAttribute('id', 'hcdeExpMonthContainer');
                    hcdeExpMonthCon.setAttribute('class', 'hcdeComponentInputContainer col-6 pe-1');

                    let hcdeExpMonthLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(2) > label');
                    hcdeExpMonthLabel.innerText = "";

                    let hcdeExpMonthInput = document.getElementById('hcdeCardExpiryMonth');
                    hcdeExpMonthInput.options[0].innerText = 'Expiration Month';
                    hcdeExpMonthInput.setAttribute('onchange', 'onInputExpMth()');

                    let hcdeExpYearCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(3)');
                    hcdeExpYearCon.setAttribute('id', 'hcdeExpYearContainer');
                    hcdeExpYearCon.setAttribute('class', 'hcdeComponentInputContainer col-6 ps-1');

                    let hcdeExpYearLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(3) > label');
                    hcdeExpYearLabel.innerText = "";
                    
                    let hcdeExpYearInput = document.getElementById('hcdeCardExpiryYear');
                    hcdeExpYearInput.options[0].innerText = 'Expiration Year';
                    hcdeExpYearInput.setAttribute('onchange', 'onInputExpYr()');

                    let hcdeZipCodeCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(4)');
                    hcdeZipCodeCon.setAttribute('id', 'hcdeZipCodeContainer');
                    hcdeZipCodeCon.setAttribute('class', 'hcdeComponentInputContainer form-floating form-group col-6 pe-1');

                    let hcdeZipCodeLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(4) > label');
                    hcdeZipCodeLabel.innerText = "";

                    let hcdeZipCodeInput = document.getElementById('hcdeZipCode');
                    hcdeZipCodeInput.setAttribute('class', 'hcdeComponentTextBox form-control');
                    hcdeZipCodeInput.setAttribute('placeholder', ' ');
                    hcdeZipCodeInput.setAttribute('maxlength', '5');
                    hcdeZipCodeInput.setAttribute('oninput', 'onInputZipCode()');
                    hcdeZipCodeInput.addEventListener("keypress", e => {
                        if (!digitsOnlyRegExp.test(e.key)) {
                            e.preventDefault();
                        }
                    });

                    let newZipCodeLabel = document.createElement('label');
                    newZipCodeLabel.setAttribute('class', 'hcdeComponentLabel d-inline-block text-start text-truncate form-label col-12');
                    newZipCodeLabel.setAttribute('id', 'hcdeZipCodeLabel');
                    newZipCodeLabel.innerText = "Zip Code";
                    hcdeZipCodeCon.appendChild(newZipCodeLabel);

                    let hcdeCrAmount = document.createElement("div");
                    hcdeCrAmount.setAttribute('class', 'hcdeComponentInputContainer form-floating form-group col-12')
                    hcdeCrAmount.setAttribute('id', 'hcdeCrAmount');

                    let hcdeCrAmountLab = document.createElement("label");
                    hcdeCrAmountLab.setAttribute('for', 'PaymentCrAmount')
                    hcdeCrAmountLab.setAttribute('class', 'hcdeComponentLabel')

                    let hcdeCrAmountInput = document.createElement("input");
                    hcdeCrAmountInput.setAttribute('type', 'text')
                    hcdeCrAmountInput.setAttribute('name', 'paymentCrAmt')
                    hcdeCrAmountInput.setAttribute('class', 'hcdeComponentTextBox form-control')
                    hcdeCrAmountInput.setAttribute('id', 'PaymentCrAmount')
                    hcdeCrAmountInput.setAttribute('maxlength', '10')
                    hcdeCrAmountInput.setAttribute('autocomplete', 'off')
                    hcdeCrAmountInput.setAttribute('placeholder', '')
                    hcdeCrAmountInput.setAttribute('oninput',`onCrAmount('${bal}')`);
                    hcdeCrAmountInput.setAttribute('onchange',`setMinValCr('${bal}')`);
                    hcdeCrAmountInput.value = '$' + bal;

                    let hcdeCrAmountLabel = document.createElement("label");
                    hcdeCrAmountLabel.setAttribute('class', 'hcdeComponentLabel d-inline-block text-start text-truncate form-label col-12')
                    hcdeCrAmountLabel.setAttribute('id', 'PaymentAmountLbl')
                    hcdeCrAmountLabel.innerText = "Payment Amount (Minimum $5.00)";
                    hcdeCrAmount.appendChild(hcdeCrAmountLab)
                    hcdeCrAmount.appendChild(hcdeCrAmountInput)
                    hcdeCrAmount.appendChild(hcdeCrAmountLabel)
                    let hcdeCrBank = document.querySelector('#hcdeZipCodeContainer')
                    hcdeCrBank.after(hcdeCrAmount)

                    let hcdeCrdDateAmount = document.createElement("div");
                    hcdeCrdDateAmount.setAttribute('class', 'hcdeComponentInputContainer form-floating form-group col-12')
                    hcdeCrdDateAmount.setAttribute('id', 'hcdeCrDateAmt');

                    let hcdeCrDateAmountLab = document.createElement("label");
                    hcdeCrDateAmountLab.setAttribute('for', 'PaymentCrDateTime')
                    hcdeCrDateAmountLab.setAttribute('class', 'hcdeComponentLabel')

                    let hcdeCrDateTimeInput = document.createElement("input");
                    hcdeCrDateTimeInput.setAttribute('type', 'text')
                    hcdeCrDateTimeInput.setAttribute('name', 'paymentCrDate')
                    hcdeCrDateTimeInput.setAttribute('class', 'hcdeComponentTextBox form-control')
                    hcdeCrDateTimeInput.setAttribute('id', 'PaymentCrDateTime')
                    hcdeCrDateTimeInput.setAttribute('maxlength', '10')
                    hcdeCrDateTimeInput.setAttribute('autocomplete', 'off')
                    hcdeCrDateTimeInput.setAttribute('placeholder', '')
                    hcdeCrDateTimeInput.setAttribute('disabled', 'true')

                    let hcdeCrDateTimeLabel = document.createElement("label");
                    hcdeCrDateTimeLabel.setAttribute('class', 'hcdeComponentLabel d-inline-block text-start text-truncate form-label col-12')
                    hcdeCrDateTimeLabel.setAttribute('id', 'PaymentCrAmountLbl')
                    hcdeCrDateTimeLabel.innerText = formattedDateValue

                    hcdeCrdDateAmount.appendChild(hcdeCrDateAmountLab)
                    hcdeCrdDateAmount.appendChild(hcdeCrDateTimeInput)
                    hcdeCrdDateAmount.appendChild(hcdeCrDateTimeLabel)
                    let hcdeCrDt = document.querySelector('#hcdeCrAmount')
                    hcdeCrDt.after(hcdeCrdDateAmount)

                    let hcdeButtonCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentButtonContainer');
                    hcdeButtonCon.setAttribute('id', 'hcdeSaveButtonContainer');

                    let hcdeSaveButton = document.getElementById('hcdeComponentSubmitButton');
                    hcdeSaveButton.setAttribute('value', 'Save');

                    // eslint-disable-next-line no-undef
                    checkCardInfo();
                    
                });
            }
            setTimeout(() => (
                this.cardOptionTitle = true,
                this.cardImg = true,
                this.showAddPaymentMethod = false
            ), 135);  
        });
      },
      bankOption() {
        this.paymentModal.show();
        this.bankOptionTitle = true
        this.cardOptionTitle = false
        this.hcdeReady = false;
        this.hcdeReadyWallet = true;
        const bankscripts = [
            this.hcdeWalletComponent				
        ];
        let formattedDateValue = this.formattedDate
        let bal = this.totalBalanceOwed;
        bankscripts.forEach(script => {
            let scriptFound = document.head.querySelector(`[src="${ script }"`) ? true : false;
            if (scriptFound) {
                this.removeJSfile(script);
            }
            let tag = document.head.querySelector(`[src="${ script }"`);
            if (!tag) {
                tag = document.createElement("script");
                tag.setAttribute("src", script);
                tag.setAttribute("type", 'text/javascript');
                document.head.appendChild(tag); 
                tag.addEventListener('load', function () {
                  
                    // eslint-disable-next-line no-undef
                    this.hcdeTagString = new HCDEWalletServiceComponent();
                    const digitsOnlyRegExp = /^[0-9]*$/;
                    let hcdeBankDetailCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(1)');
                    hcdeBankDetailCon.setAttribute('id', 'hcdeBankDetailContainer');
                    hcdeBankDetailCon.setAttribute('class', 'hcdeComponentInputContainer form-floating form-group col-12');

                    let hcdeRouteNumLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(1) > label');
                    hcdeRouteNumLabel.innerText = "";

                    let hcdeRouteNumInput = document.getElementById('bankRoutingNumber');
                    hcdeRouteNumInput.setAttribute('class', 'hcdeComponentTextBox form-control');
                    hcdeRouteNumInput.setAttribute('placeholder', ' ');
                    hcdeRouteNumInput.setAttribute('maxlength', '9');
                    hcdeRouteNumInput.setAttribute('oninput', 'onInputRouteNum()');
                    hcdeRouteNumInput.addEventListener("keypress", e => {
                        if (!digitsOnlyRegExp.test(e.key)) {
                            e.preventDefault();
                        }
                    });

                    let newRoutingNumberLabel = document.createElement('label');
                    newRoutingNumberLabel.setAttribute('class', 'hcdeComponentLabel d-inline-block text-start text-truncate form-label col-12');
                    newRoutingNumberLabel.setAttribute('id', 'hcdeRouteNumberLabel');
                    newRoutingNumberLabel.innerText = "Routing Number";
                    hcdeBankDetailCon.appendChild(newRoutingNumberLabel);

                    let hcdeAcctNumCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(2)');
                    hcdeAcctNumCon.setAttribute('id', 'hcdeAccountNumberContainer');
                    hcdeAcctNumCon.setAttribute('class', 'hcdeComponentInputContainer form-floating form-group col-12');

                    let hcdeAcctNumLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(2) > label');
                    hcdeAcctNumLabel.innerText = "";

                    let hcdeAcctNumInput = document.getElementById('bankAccountNumber');
                    hcdeAcctNumInput.setAttribute('class', 'hcdeComponentTextBox form-control');
                    hcdeAcctNumInput.setAttribute('placeholder', '');
                    hcdeAcctNumInput.setAttribute('maxlength', '17');
                    hcdeAcctNumInput.setAttribute('oninput', 'onInputAcctNum()');
                    hcdeAcctNumInput.addEventListener("keypress", e => {
                        if (!digitsOnlyRegExp.test(e.key)) {
                            e.preventDefault();
                        }
                    });

                    let newAcctNumberLabel = document.createElement('label');
                    newAcctNumberLabel.setAttribute('class', 'hcdeComponentLabel d-inline-block text-start text-truncate form-label col-12');
                    newAcctNumberLabel.setAttribute('id', 'hcdeAccountNumberLabel');
                    newAcctNumberLabel.innerText = "Account Number";
                    hcdeAcctNumCon.appendChild(newAcctNumberLabel);

                    let hcdeAcctTypeCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(3)');
                    hcdeAcctTypeCon.setAttribute('id', 'hcdeAccountTypeContainer');
                    hcdeAcctTypeCon.setAttribute('class', 'hcdeComponentInputContainer col-12');

                    let hcdeAcctTypeLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(3) > label');
                    hcdeAcctTypeLabel.innerHTML = '';

                    let hcdeAcctTypeInput = document.getElementById('bankAccountType');
                    hcdeAcctTypeInput.options[0].innerText = 'Bank account type';
                    hcdeAcctTypeInput.setAttribute('onchange', 'onInputAcctType()');

                    let hcdeAmount = document.createElement("div");
                    hcdeAmount.setAttribute('class', 'hcdeComponentInputContainer form-floating form-group col-12')
                    hcdeAmount.setAttribute('id', 'hcdeAmount');

                    let hcdeAmountLab = document.createElement("label");
                    hcdeAmountLab.setAttribute('for', 'PaymentAmount')
                    hcdeAmountLab.setAttribute('class', 'hcdeComponentLabel')

                    let hcdeAmountInput = document.createElement("input");
                    hcdeAmountInput.setAttribute('type', 'text')
                    hcdeAmountInput.setAttribute('name', 'paymentAmt')
                    hcdeAmountInput.setAttribute('class', 'hcdeComponentTextBox form-control')
                    hcdeAmountInput.setAttribute('id', 'PaymentAmount')
                    hcdeAmountInput.setAttribute('maxlength', '10')
                    hcdeAmountInput.setAttribute('autocomplete', 'off')
                    hcdeAmountInput.setAttribute('placeholder', '')
                    hcdeAmountInput.setAttribute('oninput',`onAmount('${bal}')`);
                    hcdeAmountInput.setAttribute('onchange',`setMinVal('${bal}')`);
                    hcdeAmountInput.value = '$' + bal;
                    
                    let hcdeAmountLabel = document.createElement("label");
                    hcdeAmountLabel.setAttribute('class', 'hcdeComponentLabel d-inline-block text-start text-truncate form-label col-12')
                    hcdeAmountLabel.setAttribute('id', 'PaymentAmountLbl')
                    hcdeAmountLabel.innerText = "Payment Amount (Minimum $5.00)";
                    hcdeAmount.appendChild(hcdeAmountLab)
                    hcdeAmount.appendChild(hcdeAmountInput)
                    hcdeAmount.appendChild(hcdeAmountLabel)
                    let hcdeCardBank = document.querySelector('#hcdeAccountTypeContainer')
                    hcdeCardBank.after(hcdeAmount)
                    
                    let hcdeDateAmount = document.createElement("div");
                    hcdeDateAmount.setAttribute('class', 'hcdeComponentInputContainer form-floating form-group col-12')
                    hcdeDateAmount.setAttribute('id', 'hcdeDateAmt');

                    let hcdeDateAmountLab = document.createElement("label");
                    hcdeDateAmountLab.setAttribute('for', 'PaymentDateTime')
                    hcdeDateAmountLab.setAttribute('class', 'hcdeComponentLabel')

                    let hcdeDateTimeInput = document.createElement("input");
                    hcdeDateTimeInput.setAttribute('type', 'text')
                    hcdeDateTimeInput.setAttribute('name', 'paymentDate')
                    hcdeDateTimeInput.setAttribute('class', 'hcdeComponentTextBox form-control')
                    hcdeDateTimeInput.setAttribute('id', 'PaymentDateTime')
                    hcdeDateTimeInput.setAttribute('maxlength', '10')
                    hcdeDateTimeInput.setAttribute('autocomplete', 'off')
                    hcdeDateTimeInput.setAttribute('placeholder', '')
                    hcdeDateTimeInput.setAttribute('disabled', 'true')

                    let hcdeDateTimeLabel = document.createElement("label");
                    hcdeDateTimeLabel.setAttribute('class', 'hcdeComponentLabel d-inline-block text-start text-truncate form-label col-12')
                    hcdeDateTimeLabel.setAttribute('id', 'PaymentAmountLbl')
                    hcdeDateTimeLabel.innerText = formattedDateValue
                    hcdeDateAmount.appendChild(hcdeDateAmountLab)
                    hcdeDateAmount.appendChild(hcdeDateTimeInput)
                    hcdeDateAmount.appendChild(hcdeDateTimeLabel)
                    let hcdeDt = document.querySelector('#hcdeAmount')
                    hcdeDt.after(hcdeDateAmount)

                    let hcdeBankButtonCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentButtonContainer');
                    hcdeBankButtonCon.setAttribute('id', 'hcdeSaveBankButtonContainer');

                    let hcdeSaveBankButton = document.getElementById('hcdeComponentSubmitButton');
                    hcdeSaveBankButton.setAttribute('value', 'Save');

                    // eslint-disable-next-line no-undef
                    checkBankInfo();
                  
                });
            }
            setTimeout(() => (
                this.bankOptionTitle = true,
                this.showAddPaymentMethod = false
            ), 135);   
        });
      },
      ModalClose() {
        this.paymentModal.hide();
        this.hcdeReady = false;
        this.hcdeReadyWallet = false;
      },
      get_nth_suffix(date) {
        switch (date) {
          case 1:
          case 21:
          case 31:
            return "st";
          case 2:
          case 22:
            return "nd";
          case 3:
          case 23:
            return "rd";
          default:
            return "th";
        }
      },
      currentDate() {
        const current = new Date();
        const day = new Date(current).toLocaleString("en-us", {
          weekday: "long",
        });
        const month = current.toLocaleString("en-us", {
          month: "short",
        });
        const ordinal = this.get_nth_suffix(current);
        const today =
          "Today, " + day + ", " + month + " " + current.getDate() + ordinal;
        this.formattedDate = day + ", " + month + " " + current.getDate()
        return today;
      }
    },
    updated() {
    this.isBrimOn = process.env.VUE_APP_BRIM;
    if(this.isBrimOn == 'true'){
      this.createHcdeTagListener()  
      }  
    },
    mounted(){
      // gtm for scheduled_to_pay
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'qbp_scheduled_to_pay',
        flow:{
          name: 'qbp',
          step: 'scheduled_to_pay_page'
        },
      });
      let attributesData = new Map();
      attributesData.set('showLoading', true);
      this.$store.dispatch(SET_DATA, {attributes:attributesData});
      // get the data which is required to display page
      this.installments =  JSON.parse(this.$store.getters[GET_DATA]("installmentsArray"))
      this.Iframe = JSON.parse(this.$store.getters[GET_DATA]("EpwfIframe"))
      this.totalBalanceOwed = JSON.parse(this.$store.getters[GET_DATA]("amountOwed"))
      this.remainingAmt = JSON.parse(this.$store.getters[GET_DATA]("remainingAmount"))
      this.accountNumber = JSON.parse(this.$store.getters[GET_DATA]("selectedAccountNumber"))
      this.currMonthlyAmt = JSON.parse(this.$store.getters[GET_DATA]("currentMonthlyAmount"))
      this.adjustedAmountAvailable = JSON.parse(this.$store.getters[GET_DATA]("adjustedAmountAvl"))
      this.statusMessage = JSON.parse(this.$store.getters[GET_DATA]("status"));
      this.showPaNoti = JSON.parse(this.$store.getters[GET_DATA]("pmtArrangementOnAccount"));
      this.sessionId = JSON.parse(this.$store.getters[GET_DATA]("EpwfSessionId"));
      this.brimOn = process.env.VUE_APP_BRIM;
      this.env = process.env.VUE_APP_env;
      if(this.brimOn == "true"){
        this.WalletJSEncrypt = links.WalletJSEncrypt;
        this.hcdeWalletServiceApi = links.hcdeWalletServiceApi;
        this.hcdeTokenServiceApi = links.hcdeTokenServiceApi;
        this.hcdeWalletComponent = links.hcdeWalletComponent;
        this.hcdeTokenComponent = links.hcdeTokenComponent;
        this.brim = true;
      }else {
        this.brim = false;
      }
      this.paymentModal = new Modal(document.getElementById('myModal'),{
        backdrop: 'static',
        keyboard: false
      });
      this.currentDate();
      if(!this.adjustedAmountAvailable){
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "qbp_error",
          accountNumber: pii(this.accountNumber),
          errorName: "amount unavailable",
          flow: {
            name: "qbp",
            step: "payment flow",
          },
        });
      }
      this.paymentDueDate = JSON.parse(this.$store.getters[GET_DATA]("paymentDate"))
      attributesData.set("totalBalanceOwed", JSON.stringify(this.totalBalanceOwed));
      attributesData.set('showLoading', false);
      this.$store.dispatch(SET_DATA, {attributes:attributesData});
      // Fetching card or bank store value after checking which payment mode is allowed
      this.isAchAllowed = JSON.parse(this.$store.getters[GET_DATA]('isAchAllowed'));
      this.isCardAllowed = JSON.parse(this.$store.getters[GET_DATA]('isCardAllowed'));
      // eslint-disable-next-line
      window.tokenStatus = function(){
        hcdeStatus.showTokenStatus(window.statusVar);
        self.checkStatus(window.statusVar);
      };
      // eslint-disable-next-line
      window.walletStatus = function(){
        hcdeStatus.showWalletStatus(window.statusVar);
        self.checkStatus(window.statusVar);
      };
    },
  }
</script>
<style scoped>
  #qbp-intro-wrapper {
    position: relative;
    font-family: MaisonNeue-Light, sans-serif;
  }
  .importantMessage {
    background-image: url(../../assets/images/icon-important.svg);
    background-repeat: no-repeat;
    height: 32px;
    width: 32px;
    position: absolute;
  }
  #qbp{
    color: black;
    font-size: 25px;
    line-height: 1;
    margin-top: 5px;
  }
  .qbpP2P_mt_0 {
    margin-top: 0 !important;
  }
  .qbpP2P_mb_0 {
    margin-bottom: 0 !important;
  }
  .qbpP2P_bb_none {
    border-bottom: none !important;
  }
  .qbpP2P_padding {
    padding-left: 1.5rem !important;
    text-indent: 0 !important;
    background-position: 0 0.5rem;
  }
  .qbpP2P_fs_init {
    font-style: initial !important;
  }
  .qbpP2P_fs_16 {
    font-size: 16px;
  }
  .qbpP2P_fs_40 {
    font-size: 40px;
  }
  .qbpP2P_space_1 {
    margin: 1rem;
  }
  .qbpP2P_InstlW1 {
    display: inline-block;
    width: 6rem;
  }
  .qbpP2P_InstlW2 {
    display: inline-block;
    width: 10rem;
    margin-right: 1.5rem;
  }
  .qbpP2P_FR {
    float: right;
  }
  .currentMnthlyAmt{
    display:flex;
  }
  .paymentDueDate{
    flex-grow: 7;
  }
  #qbpP2P_InstallmentsULOverride {
    margin-bottom: 1rem;
    list-style-type: none;
    padding-left: 2px;
  }
  .qbpP2P_OtherAmtW3 {
    display: inline-block;
    width: 11rem;
  }
  .qbpP2P_display_inline_block {
    display: inline-block;
  }
  .qbpP2P_w_8 {
    width: 8rem;
  }
  .qbpP2P_v_align_bottom {
    vertical-align: bottom;
  }
  .qbpP2P_hr {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border-top: 1px solid #ccc;
  }
  .qbp-bold-black {
    color: #000;
    font-weight: bold;
  }
  strong {
    color: #000;
    font-weight: bold;
  }
  .qbp-totalBalOwed-payment {
    display: flex;
  }
  .qbp-totalBalOwed {
    flex-grow: 5;
    color: #000;
    font-weight: bold;
  }
  .qbp-payment-due {
    flex-grow: 7;
  }
  .qbp-instalment {
    padding-left: 5px;
  }
  .align-div{
    display: inline-flex;
    margin-top: 1rem;
    margin-bottom: 1rem;
    align-items: center;
    height: auto;
    vertical-align: middle;
  }
  .icon-info-style {
    width: 18px;
    height: 18px;
  }
  .padding-left-2 {
    padding-left: 2px;
  }
  #dssp-intro-wrapper h1 {
    color: #14161A;
    font-size: 36px;
    letter-spacing: 0px;
    margin-bottom: 16px;
    opacity: 1;
    padding: 0;
    text-align: center;
  }
  #dssp-intro-wrapper h2 {
    color: #14161A;
    font-size: 24px;
    letter-spacing: 0.24px;
    opacity: 1;
    text-align: center;
    margin-bottom: 0;
  }
  .qbp-instalment table {
      caption-side: top;
  }
  .qbp-instalment table caption {
    color: #000;
    font-weight: bold;
    font-size: 16px;
    padding: 8px;
  }
  .total-amount-banner {
    background: rgba(0, 71, 187, 0.06);
  }
  .pa-info {
    padding: 1rem;
    box-shadow: 0px 1px 4px #00000029;
    border: 1px solid #000000;
    border-radius: 8px;
    height: auto;
    margin: auto auto 1rem auto;
    display: inline-flex;
  }
  .info-img {
    width: 22px;
    height: 22px;
  }
  .span-text-pa {
    font-family: Maison Neue, sans-serif;
    font-size: 20px;
    color: #14161A;
    letter-spacing: 0.2px;
    margin-left: 0.2rem;
  }
  #pa-notify-close.btn-close{
    margin-left: 5rem;
    opacity: 1;
  }
  #pa-notify-closee.btn-close:focus{
    box-shadow: none;
  }
  .qbp-confirm-details {
    width: 100%;
    margin: 0 auto;
  }
  .qbp-details {
    padding: 40px 0 30px 0;
  }
  .qbp-left-details {
    padding-top:30px;
  }
  .confirm-iframe-error {
    width: 100%;
    margin: 0 auto;
    padding: 30px 15px;
  }
  .total-amt {
    font-family: MaisonNeue-Medium, sans-serif;
    font-size: 14px;
    color: #14161A;
    position: relative;
    padding-left: 35px;
  }
  .total-bal {
    color: #14161A;
    font-size: 36px;
    font-family: Maison Neue, sans-serif;
    position: relative;
    padding-left: 20px;
  }
  .due-on {
    font-size: 14px;
    color: #14161A;
    opacity: 0.7;
  }
  .dollar {
    font-size: 24px;
    font-family: Maison Neue, sans-serif;
    position: absolute;
    left: 0;
    top: 7px;
  }
  .confirm-payment {
    font-family: MaisonNeue-Medium, sans-serif;
    font-size: 14px;
    color: #14161A;
    position: relative;
    padding-left: 35px;
  }
  .confirm-payment:before{
    background: url("../../images/svg/settings_black.svg") no-repeat;
    position: absolute;
    left: 0;
    content: "";
    width: 24px;
    height: 24px;
    top: -3px;
  }
  .confirm-method {
    color: #14161A;
    font-size: 24px;
  }
  .confirm-account {
    font-size: 16px;
    margin-bottom: 15px;
  }
  .total-amt:before {
    background: url("../../images/receipt_black.png") no-repeat;
    position: absolute;
    left: 0;
    content: "";
    width: 24px;
    height: 24px;
    top: -3px;
  }
  .icon-circle {
    display: inline-block;
    border-radius: 60px;
    box-shadow: 0 0 2px #888;
    padding: 0.5em 0.6em;
    color: #0047bb;
    background-color: #e0e8f7;
    height: 48px;
    width: 48px;
  }
  .iframe-prepaid-border {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #00000029;
    border-radius: 8px;
    opacity: 1;
    padding: 15px 10px;
  }
  .horizontal-row {
    position: relative;
  }
  .horizontal-border {
    border: 1px solid #00000029;
  }
  .accordion-body {
    padding-top: 8px;
    padding-left: 56px;
  }
  .accordion-button {
    font-family: MaisonNeue-Book,sans-serif;
    font-size: 20px;
  }
  .accordion-button::before {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 1rem;
    content: "";
    background-image: var(--bs-accordion-btn-active-icon);
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform .2s ease-in-out;
  }
  .accordion-button:not(.collapsed)::before {
    background-image: var(--bs-accordion-btn-active-icon);
    transform: var(--bs-accordion-btn-icon-transform);
  }
  .accordion-button::after {
    display: none !important;
  }
  .accordion-button:not(.collapsed) {
    color: inherit;
    background-color: inherit;
    box-shadow: none;
  }
  .accordion-button:focus {
    box-shadow: none;
  }
  .installment-header {
    font-family: MaisonNeue-Light,sans-serif;
    font-size: 16px;
  }
  .installment-primary {
    font-family: MaisonNeue-Light,sans-serif;
    font-size: 16px;
    opacity: 0.6;
  }
  .installment-secondary {
    font-family: MaisonNeue-LightItalic,sans-serif;
    font-size: 14px;
    opacity: 0.6;
  }
  @media (min-width: 767px) {
    .total-amt:before {
      left: -30px;
    }
    .total-amt {
      padding-left:0;
    }
    .confirm-payment:before{
      left: -30px;
    }
    .confirm-payment {
      padding-left:0
    }
    .confirm-iframe-error {
      width: 60%;
      margin: 0 auto;
      padding: 30px 0;
    }
    .qbp-account-deatils {
      width: 55%;
    }
    .qbp-left-details {
      padding-top:30px;
    }
    .qbp-confirm-details {
      width: 60%;
    }
  }
  @media (max-width: 767px) {
    .qbp-banner-img img{
      width: 175px;
    }
    .installment-secondary {
      font-size: 12px !important;
    }
  }
.qbp-payment-cta {
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #0047BB;
  color: #0047BB;
  margin: 10px;
}
.text-center {
  text-align: center;
}
</style>