<template>
    <div v-if="isDataAvailable">
        <div class="container" id="post-login-bill-success">
            <div class="row print-container">
                <h2 class="text-start text-placement">Thanks for your payment!</h2>
                <h2 class="text-start text-placement">Confirmation number: {{paymentConfirmationNumber}}</h2>
                <div class="details" v-if="showPaymentBlock">
                                <div class="text-summary">
                                    <h2>Payment Summary</h2>
                                </div>
                                <div class="row">
                                    <div class="col-2 col-xl-1">
                                        <div class="bill-icon"><img alt="payment-icon"
                                                src="../../images/attach_money_black_24dp.svg" /></div>
                                    </div>
                                    <div class="text-pmnt col-7 col-xl-9">Total Payment</div>
                                    <div class="payment-amt col-3 col-xl-2">${{totalPayment }}</div>
                                </div>
                                <div class="horizontal-bord offset-2 offset-xl-1"></div>
                                <div class="row">
                                    <div class="text-conv col-7 col-xl-9 offset-2 offset-xl-1">Payment Amount</div>
                                    <div class="text-conv-fee payment-amt col-3 col-xl-2">${{ paymentAmount}}</div>
                                </div>
                                <div class="row">
                                    <div class="text-conv col-7 col-xl-9 offset-2 offset-xl-1">Convenience fee</div>
                                    <div class="text-conv-fee payment-amt col-3 col-xl-2">${{ convenienceFee}}</div>
                                </div>
                                <div class="row marg-top-detail">
                                    <div class="col-2 col-xl-1">
                                        <div class="bill-icon"><img alt="calendar-icon"
                                                src="../../images/calendar_today_black_24dp.svg" /></div>
                                    </div>
                                    <div class="col-10 col-xl-11">
                                        <div class="payment-method">{{this.displayPaymentDateLabel}}</div>
                                        <div class="payment-id">{{ paymentDate }}
                                    </div>   
                                    </div> 
                                </div>
                                <div class="row marg-top-detail">
                                    <div class="col-2 col-xl-1">
                                        <div class="bill-icon"><img alt="payment-method-icon"
                                                src="../../images/payments_black_24dp.svg" /></div>
                                    </div>
                                    <div class="col-10 col-xl-11">
                                        <div class="payment-method">Payment method</div>
                                        <div class="payment-id">{{ paymentMethod }}
                                    </div>
                                        
                                    </div>
                                    
                                </div>
                            </div>
                            <p class="text-start text-placement text-color-black">An email has also been sent to <strong>{{emailAddress}} </strong> with your receipt.</p>
                    <div class="row">
                    <div class="col-12 text-end text-style" id="printButton">
                        <img alt="print" class="icon-style" src="../../images/print_black.svg" />
                        <span class="print-style text-end" @click="printPage" v-on:keyup.enter="printPage" tabindex="0">Print this page for your records</span>
                    </div>
                    </div>
            </div>
            <div id="norton-logo" ><img src="../../images/norton.png" alt="nortonLogo" /></div>
        </div>
    </div>
    <div v-if="!isWaiting && !isDataAvailable">
        <h2>Sorry, We could not find the page which you have requested.</h2>
    </div>
    <div v-if="isWaiting && !isDataAvailable">
        <h3>Please wait While we are fetching the data for you.</h3>
    </div>
</template>
<script>
import constants from '../../js/constants'
import {GET_DATA} from '../../store/sessioncontants'
import { SET_DATA as local_setdata} from "../../store/storecontants";
import axios from 'axios'
import links from '../../js/links'

export default {
    name: 'postBillSuccess',
    data() {
        return {
            accountNumber: '',
            confirmationNumber: null,
            paymentConfirmationNumber: null,
            emailAddress: null,
            paymentAmount: '',
            convenienceFee: '',
            totalPayment: '',
            paymentDate: '',
            paymentMethod: '',
            showPaymentBlock: false,
            isDataAvailable: false,
            isWaiting: false,
            AuthSignOut: constants.AzureLogout,
            PrivacyNoticeURL: links.PrivacyNoticeURL,
            displayPaymentDateLabel: '',
            brimOn: false
        }
    },
    mounted() {
        this.brimOn = process.env.VUE_APP_BRIM;
        if(this.brimOn == 'true'){
            this.confirmationNumber = this.$store.getters[GET_DATA]('confirmationNumber') ? this.$store.getters[GET_DATA]('confirmationNumber') : 'NA'
        } else {
            this.confirmationNumber = this.$route.query.PaymentID ? this.$route.query.PaymentID : null
        }
        this.emailAddress = this.$store.getters[GET_DATA]('emailAddress') ? this.$store.getters[GET_DATA]('emailAddress') : 'NA'
        this.accountNumber = this.$store.getters[GET_DATA]('accountNumber') ? this.$store.getters[GET_DATA]('accountNumber') : 'NA'
        window.dataLayer?.push({
            event: 'lbp_success',
            flow: {
                name: 'logged in bill pay',
                step: 'bill pay success'
            },
        });
        this.getPostLoginPaymentConfirmationDetails()
    },
    methods: {
        async getPostLoginPaymentConfirmationDetails() {
            let localData = new Map();
            localData.set('showLoading', true);
            this.$store.dispatch(local_setdata, {attributes:localData});
            this.isWaiting = true
            const params = {
                accountNumber: this.accountNumber,
                paymentConfirmationNumber: this.confirmationNumber,
                emailAddress: this.emailAddress
            }
            try {
                const successfulResult = await axios.post(constants.PostLoginBillPaymentSuccessURL, params)
                if(successfulResult.data.paymentConfirmationNumber && successfulResult.data.paymentConfirmationNumber !== null && successfulResult.data.paymentConfirmationNumber !== '') {
                    this.isDataAvailable = true;
                    this.isWaiting = false;
                    this.paymentConfirmationNumber = successfulResult.data.paymentConfirmationNumber;
                    if(this.emailAddress !== null && 
                    successfulResult.data.paymentHistory.amountPaid !== null && 
                    successfulResult.data.paymentHistory.convenienceFeeAmt !== null &&
                    successfulResult.data.paymentHistory.totalPaymentAmt !== null &&
                    successfulResult.data.paymentHistory.datePaid !== null &&
                    successfulResult.data.paymentHistory.paymentMethod !== null &&
                    this.emailAddress !== '' && 
                    successfulResult.data.paymentHistory.amountPaid !== '' &&
                    successfulResult.data.paymentHistory.convenienceFeeAmt !== '' &&
                    successfulResult.data.paymentHistory.totalPaymentAmt !== '' && 
                    successfulResult.data.paymentHistory.datePaid !== '' &&
                    successfulResult.data.paymentHistory.paymentMethod !== '') {
                        this.paymentAmount = Number(successfulResult.data.paymentHistory.amountPaid).toFixed(2)
                        this.convenienceFee = Number(successfulResult.data.paymentHistory.convenienceFeeAmt).toFixed(2)
                        this.totalPayment = Number(successfulResult.data.paymentHistory.totalPaymentAmt).toFixed(2)
                        this.paymentDate = successfulResult.data.paymentHistory.paymentProcessDate
                        this.paymentMethod = successfulResult.data.paymentHistory.paymentMethod + ' account ending in ' + successfulResult.data.paymentHistory.maskedPaymentAccountID
                        const dateObj = new Date();
                        const currMonth = dateObj.getMonth() + 1;
                        const currentDate = currMonth + "/" + dateObj.getDate() + "/" + dateObj.getFullYear();
                        const newDateObj = new Date(currentDate)
                        const dateOfPayment = new Date(this.paymentDate)
                        if (dateOfPayment > newDateObj) {
                            this.displayPaymentDateLabel = 'Payment Scheduled Date'
                        } else {this.displayPaymentDateLabel = 'Payment Date'}
                        this.showPaymentBlock = true
                    } else {
                        this.showPaymentBlock = false
                    }
                } else {
                    this.isDataAvailable = false
                    this.isWaiting = false
                }
                localData.set('showLoading', false);
                this.$store.dispatch(local_setdata, {attributes:localData});
            } catch (error) {
                console.log(error)
                this.isWaiting = false
                localData.set('showLoading', false);
                this.$store.dispatch(local_setdata, {attributes:localData});
            }
        },
        printPage() {
            var restorePage = document.body.innerHtml;
            var printContent = document.getElementById('post-login-bill-success').innerHtml;
            document.body.innerHtml = printContent;
            window.print();
            document.body.innerHtml = restorePage;
        },
        signOutUser() {
            localStorage.clear();
            sessionStorage.clear();
        }
    }
}
</script>
<style>
#norton-logo {
  height: 70px;
  width: 110px;
}
.text-placement {
    margin: 0.5rem 0 1rem 0;
}
.print-text-color {
    color: #0047bb;
    text-decoration: none !important;
    cursor: pointer;
}
.print-text-color:hover {
    color: #0047bb;
    font-weight: bold;
    cursor: pointer;
}
.text-color-black {
    color: #000;
    font-size: 1.1rem;
}  
h1.text-placement {
    font-size: 36px;
}
.details {
    position: relative;
    border-radius: 8px;
    border: 1px solid #EEEEEE33;
    box-shadow: 0px 1px 4px #00000029;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    margin: 20px 0 20px 0;
    padding: 15px;
}
.text-summary {
    font-family: MaisonNeue-Medium, sans-serif;
    font-size: 24px;
    color: #14161A;
    text-align: left;
    margin-bottom : 20px;
}
.bill-icon {
    position: relative;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #E0E8F7 0% 0% no-repeat padding-box;
    opacity: 1;
}
.bill-icon img {
    position: absolute;
    top: 11px;
    left: 11px;
}
.text-pmnt {
    font-family: Maison Neue, sans-serif;
    font-size: 16px;
    color: #000000;
    text-align: left;
    margin-top: 0;
    display: flex;
    align-items: center;
}
.payment-amt {
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.horizontal-bord {
    border: 1px solid #dad7d7;
}

.text-conv {
    font-family: Maison Neue, sans-serif;
    font-size: 16px;
    color: #000000;
    text-align: left;
    margin-top: 10px;
    opacity: 0.6;
}
.text-conv-fee {
    font-family: Maison Neue, sans-serif;
    font-size: 16px;
    color: #000000;
    text-align: right;
    margin-top: 10px;
    opacity: 0.6;
}
.marg-top-detail {
    margin-top: 1rem;
}
.marg-top-heading {
    margin-top: 25px;
}
.payment-method {
    font-family: Maison Neue, sans-serif;
    font-size: 16px;
    color: #000000;
    text-align: left;
}
.payment-method-icon {
    position: relative;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #E0E8F7 0% 0% no-repeat padding-box;
    opacity: 1;
    display: inline-block;
    vertical-align: middle;
    margin-left: 30px;
}
.payment-method-icon img {
    position: absolute;
    top: 20px;
    left: 20px;
}
.payment-id {
    font-family: Maison Neue, sans-serif;
    font-size: 16px;
    color: #000000;
    text-align: left; 
    opacity: 0.6;
}
.text-style {
    color: #14161ABF;
    margin-bottom: 1rem;
    margin-top: 1rem;
    font-size: 14px;
    font-family: MaisonNeue-Medium, sans-serif;
}
.icon-style {
    width: 20px;
    height: 18px;
}
.print-style {
    font-size: 14px;
    color: #0047BB;
    text-decoration: underline;
    cursor: pointer;
}
</style>