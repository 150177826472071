// eslint-disable-next-line
const showTokenStatus = function (fstatus){
    console.log("Function status:"+fstatus);
    if(fstatus.includes("FAILURE")){
        setStyleTokenError();
    }  
}
// eslint-disable-next-line
function showWalletStatus(fstatus){
    console.log("Function status:"+fstatus);
    if(fstatus.includes("FAILURE")){
        setStyleWalletError();
    } 
}
// eslint-disable-next-line
function setStyleTokenError(){
    let list = document.getElementById("hcdeComponentErrorBlock").getElementsByTagName("li");
    
    for (let i = 0; i < list.length; i++) {
        if(list[i].innerText.includes('Card Number')){
            let cardNumLabel = document.getElementById('hcdeCardNumberLabel');
            cardNumLabel.classList.add("text-danger");
            let cardNumInput = document.getElementById('hcdeCardNumber');
            cardNumInput.classList.add("is-invalid");
        } else if(list[i].innerText.includes('Expiration Month')) {
            let expMonthDropdown = document.getElementById('hcdeCardExpiryMonth');
            expMonthDropdown.classList.add("text-danger");
            expMonthDropdown.classList.add("is-invalid");
        } else if(list[i].innerText.includes('Expiration Year')) {
            let expYearDropdown = document.getElementById('hcdeCardExpiryYear');
            expYearDropdown.classList.add("text-danger");
            expYearDropdown.classList.add("is-invalid");
        } else if(list[i].innerText.includes('Expiration Date')) {
            let expMonthDropdown = document.getElementById('hcdeCardExpiryMonth');
            expMonthDropdown.classList.add("text-danger");
            expMonthDropdown.classList.add("is-invalid");
            let expYearDropdown = document.getElementById('hcdeCardExpiryYear');
            expYearDropdown.classList.add("text-danger");
            expYearDropdown.classList.add("is-invalid");
        } else if(list[i].innerText.includes('ZIP Code')){
            let zipCodeLabel = document.getElementById('hcdeZipCodeLabel');
            zipCodeLabel.classList.add("text-danger");
            let zipCodeInput = document.getElementById('hcdeZipCode');
            zipCodeInput.classList.add("is-invalid");
        }
    }
}
// eslint-disable-next-line
function setStyleWalletError(){
    let list = document.getElementById("hcdeComponentErrorBlock").getElementsByTagName("li");
      
    for (let i = 0; i < list.length; i++) {
        if(list[i].innerText.includes('Routing Number')){
            let routeNumLabel = document.getElementById('hcdeRouteNumberLabel');
            routeNumLabel.classList.add("text-danger");
            let routeNumInput = document.getElementById('bankRoutingNumber');
            routeNumInput.classList.add("is-invalid");
        } else if(list[i].innerText.includes('Account Number')) {
            let acctNumLabel = document.getElementById('hcdeAccountNumberLabel')
            acctNumLabel.classList.add("text-danger");
            let acctNumInput = document.getElementById('bankAccountNumber');
            acctNumInput.classList.add("is-invalid");
        } else if(list[i].innerText.includes('Account Type')) {
            let acctTypeDropdown = document.getElementById('bankAccountType');
            acctTypeDropdown.classList.add("text-danger");
            acctTypeDropdown.classList.add("is-invalid");
        }
    }
}
export default {
    showTokenStatus,
    showWalletStatus
}